import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";
import AuthState, { UserProfile } from "types/systems/auth-state";

const authSlice: Slice<AuthState> = createSlice({
  name: "auth",
  initialState: {},
  reducers: {
    setUser: (state: AuthState, { payload }: PayloadAction<UserProfile | undefined>) => {
      state.user = payload;
    }
  }
});

export const { setUser, removeUser } = authSlice.actions;
export default authSlice.reducer;
