import { Button, Col, Menu, Radio, Row, Space, Typography } from "antd";
import { FC, Fragment, useState, useEffect } from "react";
import { CaretUpOutlined, CaretDownOutlined, ArrowUpOutlined } from "@ant-design/icons";

import "./index.scss";
import localization from "pages/CRMAccountManagement/localization";

const SortPopover: FC<{
  sortBy?: string;
  sortDirection?: "asc" | "desc";
  onSubmit: (sortBy?: string, sortDirection?: "asc" | "desc") => void;
  onCancel: () => void;
  onReset: () => void;
  show?: boolean;
  sortByData: Record<string, string>;
}> = ({ sortBy, sortDirection, onSubmit, onCancel, onReset, show, sortByData }) => {
  const [currentSortDirection, setCurrentSortDirection] = useState<"asc" | "desc" | undefined>(sortDirection);
  const [currentSortBy, setCurrentSortBy] = useState<string | undefined>(sortBy);

  useEffect(() => {
    if (show) {
      setCurrentSortDirection(sortDirection);
      setCurrentSortBy(sortBy);
    }
  }, [show, sortBy, sortDirection]);

  const onSort = () => {
    onSubmit(currentSortBy, currentSortDirection);
    onCancel();
  };

  const onResetSort = () => {
    onReset();
    onCancel();
  };

  return (
    <Menu className="px-3 py-2 border viewSort" style={{ minWidth: 300 }}>
      <Space size="small" direction="vertical" className="w-100">
        <Row>
          <Col span={24}>
            <ArrowUpOutlined style={{ color: '#777E91' }}/>
            <span style={{ color: '#777E91', paddingLeft: 8, textTransform: 'uppercase' }}>{localization.sort}</span>
          </Col>
        </Row>
        {Object.entries(sortByData).map(([key, value]) => (
          <Fragment key={key}>
            <Row justify="space-between" className="align-items-center">
              <Col span={21}>
                <Radio
                  value={key}
                  checked={currentSortBy === key}
                  onChange={(e) => {
                    setCurrentSortBy(e.target.value);
                    setCurrentSortDirection("desc");
                  }}
                  style={currentSortBy === key ? { color: '#00747a'} : {}}
                >
                  {value}
                </Radio>
              </Col>
              <Col span={3} className="d-flex btn-sort-list-item">
                <Radio.Group
                    value={currentSortBy === key ? currentSortDirection : undefined}
                    onChange={(e) => setCurrentSortDirection(e.target.value)}>
                  <Radio.Button value="asc">
                    <CaretUpOutlined style={{ color: currentSortDirection === 'asc' && currentSortBy === key ? '#00747a' : '#777E91' }}/>
                  </Radio.Button>
                  <Radio.Button value="desc">
                    <CaretDownOutlined style={{ color: currentSortDirection === 'desc' && currentSortBy === key ? '#00747a' : '#777E91' }}/>
                  </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Fragment>
        ))}
        <Menu.Divider />
        
        <Row className="d-flex" style={{ gap: 10, justifyContent: 'flex-end' }}>
          <Col span={8}>
            <Button className="btnReset" block onClick={onResetSort}>
                <Typography.Text ellipsis={true} className="txtReset">
                    {localization.reset}
                </Typography.Text>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              className="btnSort"
              block onClick={onSort}
            >
                <Typography.Text ellipsis={true} className="txtSort">
                    {localization.sort}
                </Typography.Text>
            </Button>
          </Col>
        </Row>
      </Space>
    </Menu>
  )
};

export default SortPopover;