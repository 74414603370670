import { v4 } from "uuid";
import { Section, SubSection, Checklist } from "types/systems";
import { ChecklistItemSubmission, Suggestion } from "types/models";
import { CheckResult } from "constants/checklist-record";

export const getFormValue = (
  formValue: any,
  sections: Section[],
  typeSubmit: "Submitted" | "Draft",
  oldStatus?: "Submitted" | "Draft" | "No Inspection" | "Signed-Off",
  oldSubmissions?: ChecklistItemSubmission[],
  draftChecklistId?: string
): { checkItems: ChecklistItemSubmission[]; issuesCreated: number } => {
  const checklistSubmission: ChecklistItemSubmission[] = [];

  sections.forEach((s) =>
    s.subs.forEach((ss) =>
      ss.checklists.forEach((item) =>
        checklistSubmission.push({
          checklistItem: item.label,
          checklistItemNumber: item.id,
          subSectionName: ss.label,
          subSectionNumber: ss.id,
          sectionName: s.label,
          sectionNumber: s.id,
          checkResult: formValue[`${item.id}.checkResult`],
          comments: formValue[`${item.id}.comments`],
          isDefect: formValue[`${item.id}.isDefect`],
          failureType: formValue[`${item.id}.failureType`],
          failureSubType: formValue[`${item.id}.failureSubType`],
          images: formValue[`${item.id}.images`],
          documents: formValue[`${item.id}.documents`],
          checklistItemId: draftChecklistId || formValue[`${item.id}.checklistItemId`],
          compulsory: item.required || false,
          createDefect:
            (typeSubmit === "Submitted" &&
              formValue[`${item.id}.isDefect`] &&
              // CASE 1: The first submission
              (!oldSubmissions?.length ||
                // CASE 2: The previous submission is "Draft" and doesn't have any submissions previously
                (oldStatus === "Draft" &&
                  !oldSubmissions?.find((oldCheckItem) => oldCheckItem.checklistItemNumber === item.id)
                    ?.oldCheckResult) ||
                // CASE 3: The previous submission is "Submitted"
                (oldStatus === "Submitted" &&
                  oldSubmissions?.find((oldCheckItem) => oldCheckItem.checklistItemNumber === item.id)?.checkResult ===
                    CheckResult.Pass) ||
                // CASE 4: The previous submission is "Draft" -> compare to the previous "Submitted" Record (field: oldCheckResult)
                oldSubmissions?.find((oldCheckItem) => oldCheckItem.checklistItemNumber === item.id)?.oldCheckResult ===
                  CheckResult.Pass)) ||
            false // default value is false
        })
      )
    )
  );

  return { checkItems: checklistSubmission, issuesCreated: checklistSubmission.filter((x) => x.isDefect).length };
};

export const getFormTemplate = (formValue: any, sections: Section[]): Section[] => {
  sections.forEach((section) => {
    section.label = formValue[section.id];
    section.subs.forEach((sub) => {
      sub.label = formValue[`${section.id}.${sub.id}`];
      sub.checklists.forEach(checkItem => {
        checkItem.label = formValue[`${section.id}.${sub.id}.${checkItem.id}`];
      })
    })
  });

  return [...sections];
}

export const parseTemplate = (templateStr = ""): Section[] => {
  try {
    const formSchema = JSON.parse(templateStr) as Section[];
    return formSchema;
  } catch (err: any) { }
  return [];
};

export const cleanCheckItem = (item: Checklist): Checklist => ({ ...item, id: v4() });

export const cleanSubSection = ({ label, checklists }: SubSection): SubSection => ({
  label,
  id: v4(),
  checklists: checklists.map((item) => cleanCheckItem(item))
});

export const cleanSection = ({ label, subs }: Section): Section => ({
  label,
  id: v4(),
  subs: subs.map((sub) => cleanSubSection(sub))
});

export const getNewSuggestion = (schema: Section[], oldSuggestion: Suggestion[]): Suggestion[] => {
  const result: Suggestion[] = [];

  schema.forEach(sec => {
    sec.subs.forEach(sub => {
      sub.checklists.forEach(checkItem => {
        if (!oldSuggestion.find(old => old.section === sec.label && old.subSection === sub.label && old.item === checkItem.label)) {
          result.push({ section: sec.label, subSection: sub.label, item: checkItem.label });
        }
      })
    })
  })

  return result;
}