import { Alert as AlertAntd } from "antd";
import { useEffect, useState, useImperativeHandle, createRef } from "react";

import "./index.scss";

interface RefProps {
  show: (msg: string) => void;
  hide: () => void;
};

const AlertRef = createRef<RefProps>();
const FlashMsg = () => {
  const [isShowAlertMsg, setIsShowAlertMsg] = useState(false);
  const [msg, setMsg] = useState<string>();

  const _onCloseAlert = () => {
    setIsShowAlertMsg(false);
    setMsg(undefined);
  };

  const _onShowAlert = (message: string) => {
    setIsShowAlertMsg(true);
    setMsg(message)
  };

  useEffect(() => {
    // if (isShowAlertMsg) {
    //   setTimeout(_onCloseAlert, 3000);
    // }
  }, [isShowAlertMsg]);

  useImperativeHandle(
    AlertRef,
    () => ({
      show: _onShowAlert,
      hide: _onCloseAlert,
    }),
    []
  );

  if (!isShowAlertMsg) {
    return <></>;
  }

  return (
    <AlertAntd
      className="alert-component"
      showIcon
      message={msg}
      type="success"
      closable
      afterClose={_onCloseAlert}
    />
  );
};

export  { AlertRef };
export default FlashMsg;
