import { CloseCircleFilled, ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile } from "antd/lib/upload";
import _ from "lodash";
import moment from "moment";
import Resizer from "react-image-file-resizer";
import { Link } from "react-router-dom";
import { ColumnTitleType, TYPE_COLUMN } from "types/models/account-management";
import localization from "../localization";
import constants from "./constants";

const Helpers = {
  isNullOrEmpty(value: string) {
    return typeof value != "string" || _.isNil(value) || !value || _.isEmpty(value.trim());
  },
  deleteDuplicated(options: any, columName: string) {
    const uniqueArr = options.filter((item: any, index: number) => {
      return options.findIndex((elem: any) => elem[columName] === item[columName]) == index;
    });

    return uniqueArr;
  },
  getColumnTitle<T>(dataTitles: ColumnTitleType[]): ColumnsType<T> {
    return dataTitles.filter((d) => !d?.isHidden).map(({ title, type, pathname, columnName, width, isOpenNewPage, hasPermission, onClickAction }) => {
      let fncTextRender: any = undefined;

      switch (type) {
        case TYPE_COLUMN.LINK:
          fncTextRender = (text: string, record: any) => {
            if (pathname) {
              return (
                <Link
                  style={{ lineHeight: "20px", color: "#519097", textDecoration: "none" }}
                  to={{
                    pathname: hasPermission ? `${pathname}${text.replace(/\//g, ' ')}?id=${record.id}` : undefined,
                  }}
                  rel="noopener noreferrer"
                  target={isOpenNewPage ? "_blank" : undefined}
                >
                  {text}
                </Link>
              )
            }

            return (
              <a style={{ textDecoration: 'none' }} target="_blank" href={text}>{text}</a>
            );
          };
          break;

        case TYPE_COLUMN.DATE:
          fncTextRender = (date: string) => (date ? moment(date).format(constants.DD_MM_YYYY) : "");
          break;

        case TYPE_COLUMN.LABEL:
          fncTextRender = (text: string) => <label className="lblTable">{text}</label>;
          break;

        case TYPE_COLUMN.ACTION:
          fncTextRender = (_: string, record: any) => (
            <span
              className="lblTable"
              style={{ cursor: 'pointer' }}
              onClick={() => onClickAction?.(record.id as string)}>
              {localization.delete}
            </span>
          );
          break;

        default:
          break;
      }

      return {
        title: <label className="titleTable">{title}</label>,
        dataIndex: columnName,
        key: columnName,
        render: fncTextRender,
        width
      };
    });
  },
  resizeFile(file: RcFile) {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        768,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });
  },
  getFileExtension(filename: string) {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  },
  showModalConfirm(title: string, content: string, fncCallback: () => void) {
    return (
      Modal.confirm({
        centered: true,
        title,
        icon: <ExclamationCircleOutlined />,
        content,
        okButtonProps:{className: 'okText'},
        cancelButtonProps: { className: 'cancelText'},
        okText: "Yes",
        cancelText: "No",
        onOk: fncCallback
      })
    );
  },
  showModalError(title: string, content: string) {
    Modal.error({
      centered: true,
      title,
      content,
      icon:<CloseCircleFilled twoToneColor="#FF4D4F" />,
      okButtonProps:{className: 'okText'}
    });
  },
  convertArrayToParams(values: any) {
    return values ? JSON.stringify(values) : null;
  },
  trim(value: string | undefined | null) {
    if (!value || value.trim() == '') {
      return undefined;
    }

    return value;
  },
  updateUrl(className: string, urlPath: string, title: string){
    const routes = document.getElementsByClassName(className);
    const lastRoute = routes.length > 0 ? routes[routes.length - 1] : undefined;

    if (lastRoute) {
     lastRoute.innerHTML = title;
      window.history.pushState({}, "", urlPath);
    }
  },
  updateUrlWithoutPushState(className: string, urlPath: string, title: string){
    const routes = document.getElementsByClassName(className);
    const lastRoute = routes.length > 0 ? routes[routes.length - 1] : undefined;

    if (lastRoute) {
     lastRoute.innerHTML = title;
    }
  },
  truncateText(name: string, numberEllipsis: number) {
    const splitName = name.split(" ");
    let counter = 0 as number;
    let concatString = "" as string;
    const resultCallback = {
      name: "",
      tooltip: false
    } as { name: string; tooltip: boolean };
    // Check adhoc case: First Name Too Long
    if (splitName[0].length > numberEllipsis) {
      resultCallback.name = name.length > numberEllipsis ? name.slice(0, numberEllipsis) + "..." : name;
      resultCallback.tooltip = true;
    } else {
      // Default case
      for (let index = 0; index < splitName.length; index++) {
        counter += splitName[index].length;
        concatString += " " + splitName[index];
        if (counter + (splitName[index + 1]?.length ?? 0) > numberEllipsis) {
          resultCallback.name = concatString.trim() + "...";
          resultCallback.tooltip = true;
          break;
        } else {
          resultCallback.name = concatString.trim();
          resultCallback.tooltip = false;
        }
      }
    }
    return resultCallback;
  }
};

export default Helpers;
