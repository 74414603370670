import { Col, Form, Input, Modal, Row, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";

import AccountManagementAPI from "api/accountManagement";
import { IResponse, TypeBodyAddClientType } from "types/models/account-management";
import localization from "../../localization";
import { Helpers } from "../../utils";
import "./index.scss";

let idTimeoutCheckClientType: NodeJS.Timeout | undefined = undefined;
const noop = () => {};

type ModalAddClientTypeProps = {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: (val: {id: string, name: string}) => void;
};

const ModalAddClientType = ({ isVisible, onClose, onConfirm }: ModalAddClientTypeProps) => {
  const [isExistClientType, setIsExistClientType] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const [form] = useForm<TypeBodyAddClientType>();

  const _onClickSubmitData = async () => {
    form
      .validateFields()
      .then(async (values : TypeBodyAddClientType) => {
        setIsSubmitted(true);
        const isValid = await AccountManagementAPI.isValidClientType(values.name.trim());

        if (!isValid) {
          setIsExistClientType(true);
          return;
        }

        const dataRespone:{message: string, data: {id: string, name: string}} =  await AccountManagementAPI.addClientType({
          name: values.name.trim(),
        })
        .catch((error) => {
          Helpers.showModalError(localization.msgCreateClientTypeError, "");
          throw error;
        });
        onConfirm(dataRespone.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsSubmitted(false);
      });
  };

  const _onChangeTxtClient = (e: any) => {
    if (!e?.target?.value || Helpers.isNullOrEmpty(e.target.value.trim())) {
      setIsExistClientType(false);
      return;
    }

    form
      .validateFields(["name"])
      .then(() => {
        if (idTimeoutCheckClientType) {
          clearTimeout(idTimeoutCheckClientType);
        }

        idTimeoutCheckClientType = setTimeout(async () => {
          try {
            const isValid = await AccountManagementAPI.isValidClientType(e.target.value.trim());

            if (form.getFieldsValue().name != "") {
              setIsExistClientType(!isValid);
            }
          } catch (error) {
            console.log(error);
          } finally {
            idTimeoutCheckClientType = undefined;
          }
        }, 1000);
      })
      .catch((error: any) => {});
  };

  useEffect(() => {
    if (isVisible) {
      form.resetFields();
      setIsExistClientType(false);
    }
  }, [isVisible]);

  return (
    <Modal
      title={localization.addClientType}
      visible={isVisible}
      onOk={_onClickSubmitData}
      onCancel={isSubmitted ? noop : onClose}
      okText={localization.submit}
      cancelText={localization.cancel}
      okButtonProps={{ className: "okText" }}
      cancelButtonProps={{ className: "cancelText" }}
      confirmLoading={isSubmitted}
      centered={true}
    >
      <Form
        className="viewAddClientType"
        layout="vertical"
        validateMessages={{ required: localization.msgRequired }}
        form={form}
      >
        <Row className="viewGeneralInformation">
          <Col span={24}>
            <Form.Item
              name="name"
              label={localization.clientType}
              validateStatus={isExistClientType ? "error" : undefined}
              help={isExistClientType ? localization.msgClientTypeIsExist : undefined}
              rules={[{ required: true }, { pattern: /.*\S.*/, message: localization.msgRequired }]}
            >
              <Input
                placeholder={localization.pleaseEnter}
                maxLength={255}
                className="txtInput"
                onChange={_onChangeTxtClient}
                disabled={isSubmitted}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalAddClientType;
