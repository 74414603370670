import "@fortawesome/fontawesome-free/css/all.min.css";
import { store } from "app/store";
import "assets/scss/tophat.scss";
import Amplify from "aws-amplify";
import ErrorBoundary from "components/ErrorBoundary";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import awsconfig from "./aws-exports";
import * as serviceWorker from "./serviceWorker";
import localForage from "localforage";
import * as memoryDriver from "localforage-driver-memory";

localForage.config({
  name: "myApp",
  version: 1.0,
  size: 100000000, // Size of database, in bytes. WebSQL-only for now.
  storeName: "keyvaluepairs", // Should be alphanumeric, with underscores.
  description: "some description"
});

localForage.defineDriver(memoryDriver);
localForage.setDriver([localForage.INDEXEDDB, localForage.WEBSQL, memoryDriver._driver]);

Amplify.configure(awsconfig);
const queryClient = new QueryClient();

ReactDOM.render(
  <StrictMode>
    <ErrorBoundary>
      <Provider store={store}>
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
