const constants = {
    FOLDER_LOGO_IMAGE_CLIENT: 'crm/accountManagement/clients/crm-client-logo',
    PAGE_SIZE: 10,
    MAX_LENGTH_NOTE: 1000,
    DD_MM_YYYY: "DD/MM/YYYY",
    DD_MM_YYYY_H_MM_A: "DD/MM/YYYY h:mm A",
    MMM_DD_YYYY: "MMM DD, YYYY",
    MAX_FILE_SIZE: 1048576, //1MB
    MAX_LENGTH_EMAIL_SENT_NOTIFY: 100,
};

export default constants;