import { SearchOutlined } from "@ant-design/icons";
import { Input, Select, Spin } from "antd";
import React, { memo, useEffect, useRef, useState } from "react";

import localization from "pages/CRMAccountManagement/localization";
import { Helpers } from "pages/CRMAccountManagement/utils";
import { useQuery } from "react-query";
import { IResData, ISuggest } from "types/models/account-management";
import "./index.scss";

let idTimeoutSearch: NodeJS.Timeout | undefined = undefined;
let idTimeoutLoadMoreOptions: NodeJS.Timeout | undefined = undefined;

type TypeCustomSelectProps = {
  fncName?: string;
  conditions: string[] | string;
  placeholder: string;
  mode?: "multiple" | "tags";
  className?: string;
  children?: React.ReactNode;
  setArrConditions?: (condition: string[]) => void;
  setStringConditions?: (condition: string | undefined) => void;
  handleError: (error: any) => void;
  getListSuggest: (nextPageIndex: number, text: string | undefined) => Promise<IResData<ISuggest>>;
  setSelectedId?: (id: string[] | string) => void;
};

const CustomSelect = (props: TypeCustomSelectProps) => {
  const {
    setStringConditions,
    setArrConditions,
    conditions,
    handleError,
    getListSuggest,
    mode,
    className,
    placeholder,
    setSelectedId,
    fncName
  } = props;

  const [nextPageIndex, setNextPageIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [options, setOptions] = useState<ISuggest[]>();
  const [reload, setReload] = useState<boolean>(false);

  const txtSearchRef = useRef<Input>(null);
  const isFirstTimesRef = useRef<boolean>(true);
  const idTimeoutReloadDataRef = useRef<NodeJS.Timeout | undefined>(undefined);

  const { data: dataFromQuey } = useQuery(
    [fncName ?? "getData", keyword, nextPageIndex, reload],
    async () => await _getOptionSuggest(nextPageIndex, keyword),
    {
      refetchOnWindowFocus: false,
      onSuccess: (newData: IResData<ISuggest>) => {
        if (nextPageIndex == 0) {
          _setOptions(newData.resModel);
        } else if (nextPageIndex > 0) {
          _setOptions([...(options ?? []), ...newData.resModel]);
        }

        setIsLoadMore(false);
        setIsLoading(false);
      }
    }
  );

  const _getOptionSuggest = async (nextPageIndex: number | null, text: string) => {
    let res: IResData<ISuggest> = {
      total: 0,
      nextPageIndex: null,
      resModel: []
    };

    if (nextPageIndex == null) {
      return res;
    }

    if (!isLoading && !options) {
      setIsLoading(true);
    }

    res = await getListSuggest(nextPageIndex, Helpers.isNullOrEmpty(text) ? undefined : text.trim());
    return res;
  };

  const _setOptions = (arrOptions: ISuggest[]) => {
    setOptions(Helpers.deleteDuplicated(arrOptions ?? [], "name"));
  };

  const _onChangeText = (element: any) => {
    const text = element?.target?.value;

    if (text == null || text == undefined) {
      return;
    }

    if (!isLoading) {
      setIsLoading(true);
    }

    if (idTimeoutSearch) {
      clearTimeout(idTimeoutSearch);
    }

    idTimeoutSearch = setTimeout(async () => {
      if (text.trim() == keyword.trim()) {
        setIsLoading(false);
      } else {
        setNextPageIndex(0);
        setKeyword(text.trim());
      }
    }, 1000);
  };

  const _onPopupScroll = (e: any) => {
    
    try {
      const { scrollTop, scrollHeight, clientHeight } = e.target;

      if (Math.round(scrollTop + clientHeight) < Math.round(scrollHeight) - 15) {
        return;
      }

      if (idTimeoutLoadMoreOptions) {
        return;
      }

      if (!dataFromQuey?.nextPageIndex || isLoading) {
        return;
      }

      setIsLoadMore(true);

      idTimeoutLoadMoreOptions = setTimeout(async () => {
        if (dataFromQuey?.nextPageIndex) {
          setNextPageIndex(dataFromQuey.nextPageIndex);
        }

        if (idTimeoutLoadMoreOptions) {
          clearTimeout(idTimeoutLoadMoreOptions);
          idTimeoutLoadMoreOptions = undefined;
        }
      });
    } catch (error) {
      handleError(error);
    }
  };

  const _onchange = (value: string[] | string | undefined) => {
    if (Array.isArray(value)) {
      if (setArrConditions) {
        setArrConditions(value);

        const contacts: string[] = [];

        for (const item of value) {
          const data = options?.find((e) => e.name == item);

          if (data?.id) {
            contacts.push(data.id);
          }
        }

        if (setSelectedId) {
          setSelectedId(contacts);
        }
      }
    } else if (setStringConditions) {
      setStringConditions(value);

      const data = options?.find((e) => e.name == value);

      if (data?.id && setSelectedId) {
        setSelectedId(data.id);
      }
    }
  };

  const _onFocusTxtSearch = () => {
    txtSearchRef.current?.focus();
  };

  useEffect(() => {
    if (isFirstTimesRef.current) {
      isFirstTimesRef.current = false;
      return;
    }


    if (idTimeoutReloadDataRef.current) {
      clearTimeout(idTimeoutReloadDataRef.current);
    }

    setOptions(undefined);

    idTimeoutReloadDataRef.current = setTimeout(() => {
      setNextPageIndex(0);
      setReload(!reload);
      idTimeoutReloadDataRef.current = undefined;
    }, 1000);
  }, [getListSuggest]);

  return (
    <Select
      className={className}
      showArrow={!conditions || conditions.length == 0}
      // allowClear={!!(conditions && conditions.length > 0)}
      allowClear={false}
      placeholder={placeholder}
      optionLabelProp="value"
      mode={mode}
      style={{ width: "100%" }}
      maxTagCount="responsive"
      onChange={_onchange}
      onClick={_onFocusTxtSearch}
      onPopupScroll={_onPopupScroll}
      value={conditions ? conditions : undefined}
      getPopupContainer={(triggerNode) => triggerNode}
      dropdownRender={(menu) => {
        return (
          <div className="popupSelected">
            <Input
              ref={txtSearchRef}
              className="txtSearchName"
              placeholder={localization.search}
              onChange={_onChangeText}
              prefix={<SearchOutlined style={{ fontSize: 16, marginRight: "2px" }} />}
              maxLength={255}
              onKeyDown={(e) => e.stopPropagation()}
            />
            <div className="containerCheckBox">
              {(!isLoading || isLoadMore) && menu}
              {(isLoading || isLoadMore) && (
                <Spin size="small" style={{ marginTop: 8, marginLeft: 8, alignSelf: "flex-start" }} />
              )}
            </div>

            {props.children}
          </div>
        );
      }}
      options={
        options
          ? options.map((item) => {
              return { value: item.name };
            })
          : undefined
      }
    />
  );
};

export default memo(CustomSelect);
