export const getQueryParams = (search: string): any => {
  let res = {};
  search.replace("?", "")
    .split("&")
    .map((param: string) => {
      const [key, value] = param.split("=");
      return { [key]: value };
    }).forEach((param: { [key: string]: string }) => {
      res = {
        ...res,
        ...param
      };
    })
  return res;
}

export const getBase64 = (file: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export const encodeUrlToFieldName = (url: string) =>
  url.replaceAll(":", "%3A")
    .replaceAll(".", "%2E");
