import { Col, Form, Input, Radio, RadioChangeEvent, Row, Switch } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { useCallback, useEffect, useRef, useState } from "react";

import AccountManagementAPI from "api/accountManagement";
import { IResData, ISuggest, RADIO_TYPE, TypeBodyAddContact } from "types/models/account-management";
import { CustomSelect } from "..";
import localization from "../../localization";
import { Helpers } from "../../utils";
import "./index.scss";

let idTimeoutCheckContact: NodeJS.Timeout | undefined = undefined;

type FormContactProps = {
  form: FormInstance<TypeBodyAddContact>;
  initialValues?: Partial<TypeBodyAddContact>;
  id?: string;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
};

function FormContact(props: FormContactProps) {
  const { form, initialValues, id, headerComponent, footerComponent } = props;

  const [radioChecked, setRadioChecked] = useState<RADIO_TYPE>(RADIO_TYPE.MR);
  const [isExistContact, setIsExistContact] = useState<boolean>(false);
  const [clientNameSelected, setClientNameSelected] = useState<string | undefined>(initialValues?.clientName);

  const inputSalutationRef = useRef<Input>(null);

  useEffect(() => {
    console.log(initialValues);

    if (!initialValues || !initialValues?.salutation) {
      return;
    }

    if (
      initialValues.salutation == RADIO_TYPE.MR ||
      initialValues.salutation == RADIO_TYPE.MRS ||
      initialValues.salutation == RADIO_TYPE.MS
    ) {
      setRadioChecked(initialValues.salutation);
      form.setFieldsValue({ salutation: initialValues.salutation });
      return;
    }

    setRadioChecked(RADIO_TYPE.OTHER);
    form.setFieldsValue({ salutation: initialValues.salutation });
    inputSalutationRef.current?.setValue(initialValues.salutation);

    setTimeout(() => {
      inputSalutationRef.current?.focus();
    }, 500);
  }, []);

  const _handleError = (error: any) => {
    console.log(error);
  };

  const _onChangeRadioButton = (e: RadioChangeEvent) => {
    const value = e.target.value;
    setRadioChecked(value);

    if (value != RADIO_TYPE.OTHER) {
      inputSalutationRef.current?.setValue("");
    }
  };

  const _onClickRadioOther = () => {
    setRadioChecked(RADIO_TYPE.OTHER);
    form.setFieldsValue({ salutation: RADIO_TYPE.OTHER });

    setTimeout(() => {
      inputSalutationRef.current?.focus();
    }, 500);
  };

  const _onValidateSalutation = () => {
    const { salutation } = form.getFieldsValue();

    if (RADIO_TYPE.OTHER == salutation) {
      return Promise.reject(localization.msgRequired);
    }

    return Promise.resolve();
  };

  const _onChangeTextValidated = async (e: any) => {
    if (!e?.target?.value || Helpers.isNullOrEmpty(e.target.value.trim())) {
      setIsExistContact(false);
      return;
    }

    form
      .validateFields(["email"])
      .then(() => {
        if (idTimeoutCheckContact) {
          clearTimeout(idTimeoutCheckContact);
        }

        idTimeoutCheckContact = setTimeout(async () => {
          try {
            const isValid = await AccountManagementAPI.isValidContact({
              id,
              email: e.target.value.trim()
            });

            if (form.getFieldsValue().email != "") {
              setIsExistContact(!isValid);
            }
          } catch (error) {
            console.log(error);
          } finally {
            idTimeoutCheckContact = undefined;
          }
        }, 1000);
      })
      .catch((error) => {
        // console.log('error', error);
      });
  };

  const _setClientNameSelected = (value: string | undefined) => {
    setClientNameSelected(value);

    form.setFieldsValue({
      clientName: value
    });
  };

  const _getListClientName = useCallback(async (nextPageIndex: number, keyword: string | undefined) => {
    const res: IResData<ISuggest> = {
      total: 0,
      nextPageIndex: null,
      resModel: []
    };

    if (nextPageIndex == null) {
      return res;
    }

    return await AccountManagementAPI.getListSuggestClientName({
      nextPageIndex,
      searchKeyword: !keyword || Helpers.isNullOrEmpty(keyword) ? undefined : keyword.trim()
    });
  }, []);

  return (
    <Form
      layout="vertical"
      validateMessages={{ required: localization.msgRequired }}
      form={form}
      initialValues={initialValues}
    >
      <Row className={`viewGeneralInformation`}>
        <Col span={4} />
        <Col span={16}>
          {headerComponent}
          <Form.Item
            name="salutation"
            label={localization.salutation}
            rules={[
              { required: true },
              { pattern: /.*\S.*/, message: localization.msgRequired },
              { validator: _onValidateSalutation }
            ]}
          >
            <Row style={{ flexDirection: "row", borderWidth: 1 }}>
              <Col span={24} style={{ borderWidth: 1, display: "flex", flexDirection: "row" }}>
                <Radio.Group onChange={_onChangeRadioButton} value={radioChecked}>
                  <Radio value={RADIO_TYPE.MR}>Mr</Radio>
                  <Radio value={RADIO_TYPE.MS}>Ms</Radio>
                  <Radio value={RADIO_TYPE.MRS}>Mrs</Radio>
                  <Radio value={RADIO_TYPE.OTHER} onClick={_onClickRadioOther}>
                    <Input
                      ref={inputSalutationRef}
                      placeholder={localization.pleaseEnter}
                      maxLength={255}
                      style={{ flex: 0.5 }}
                      className="txtInput"
                      readOnly={RADIO_TYPE.OTHER != radioChecked}
                      onClick={_onClickRadioOther}
                    />
                  </Radio>
                </Radio.Group>
              </Col>
            </Row>
          </Form.Item>
          <Row>
            <Col span={11}>
              <Form.Item
                name="firstName"
                label={localization.firstName}
                rules={[{ required: true }, { pattern: /.*\S.*/, message: localization.msgRequired }]}
              >
                <Input placeholder={localization.pleaseEnter} maxLength={255} className="txtInput" />
              </Form.Item>
            </Col>
            <Col span={2} />
            <Col span={11}>
              <Form.Item
                name="lastName"
                label={localization.lastName}
                rules={[{ required: true }, { pattern: /.*\S.*/, message: localization.msgRequired }]}
              >
                <Input placeholder={localization.pleaseEnter} maxLength={255} className="txtInput" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="clientName" label={localization.clientName} rules={[{ required: true }]}>
            <CustomSelect
              fncName="getClientName"
              placeholder={localization.pleaseSelect}
              conditions={clientNameSelected ?? ""}
              setStringConditions={_setClientNameSelected}
              handleError={_handleError}
              getListSuggest={_getListClientName}
            />
          </Form.Item>
          <Form.Item
            name="email"
            label={localization.email}
            rules={[
              { required: true, type: "email" },
              { pattern: /.*\S.*/, message: localization.msgRequired }
            ]}
            validateStatus={isExistContact ? "error" : undefined}
            help={isExistContact ? localization.msgEmailExists : undefined}
          >
            <Input
              placeholder={localization.pleaseEnter}
              maxLength={53}
              className="txtInput"
              onChange={_onChangeTextValidated}
            />
          </Form.Item>
          <Form.Item name="title" label={localization.job_title}>
            <Input placeholder={localization.pleaseEnter} maxLength={20} className="txtInput" />
          </Form.Item>
          <Form.Item name="mobilePhone" label={localization.mobilePhone}>
            <Input placeholder={localization.pleaseEnter} maxLength={20} className="txtInput" />
          </Form.Item>
          <Form.Item name="officePhone" label={localization.officePhone}>
            <Input placeholder={localization.pleaseEnter} maxLength={20} className="txtInput" />
          </Form.Item>
          <Form.Item
            name="receiveEmails"
            label={localization.markReceiveEmail}
            valuePropName="checked"
          >
            <Switch defaultChecked/>
          </Form.Item>
          <Form.Item name="description" label={localization.description}>
            <Input.TextArea
              autoSize={{ minRows: 2, maxRows: 6 }}
              rows={2}
              placeholder={localization.pleaseEnter}
              maxLength={1000}
              className="txtInput"
            />
          </Form.Item>
          {footerComponent}
        </Col>
        <Col span={4} />
      </Row>
    </Form>
  );
}

export default FormContact;
