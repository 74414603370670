import MSPlannerApi from "api/msPlanner";
import { DEFAULT_BUCKET_NAME, DEFAULT_PLAN_NAME, DEFAULT_CHANNEL_NAME } from "constants/msPlanner";
import moment from "moment";
import { ChecklistItemSubmission, ChecklistRecordDetail } from "types/models";
import { MSPlannerUploadCallback } from "types/systems/form-builder-type";
import { encodeUrlToFieldName } from "./url";

export const createMSTask = async (
  item: ChecklistItemSubmission,
  token: string,
  bucketId: string,
  planId: string,
  driveId: string,
  channelName: string,
  folderName: string,
  checklistRecord: ChecklistRecordDetail,
  msPlannerUploadCallbacks: MSPlannerUploadCallback[],
): Promise<string | undefined> => {
  const uploadCallback = msPlannerUploadCallbacks.find(x => x.id === item.checklistItemNumber)?.func;
  let references = {};
  const title = `${checklistRecord.moduleId} | ${item.checkResult} | ${item.subSectionName}_${item.checklistItem}`;

  if (uploadCallback) {
    const attachments = await uploadCallback(token, driveId, channelName, folderName);
    for (let i = 0; i < attachments.length; i++) {
      references = {
        ...references,
        [encodeUrlToFieldName(attachments[i].webUrl)]: {
          "@odata.type": "microsoft.graph.plannerExternalReference",
          "alias": attachments[i].name,
          "previewPriority": " !",
          "type": "Other"
        }
      }
    }
  }

  const createTaskRes = await MSPlannerApi.createTask(token, {
    planId,
    bucketId,
    title
  });

  if (createTaskRes) {
    const taskDetail = await MSPlannerApi.getTaskDetail(token, createTaskRes.id);
    if (taskDetail) {
      await MSPlannerApi.updateTaskDetail(
        token,
        {
          id: createTaskRes.id,
          description:
            `House Type: ${checklistRecord.houseType}` +
            `\nSection: ${item.sectionName}` +
            `\nSub-section: ${item.subSectionName}` +
            `\nCheck item: ${item.checklistItem}` +
            `\nFailure Type: ${item.failureType}` +
            "\n-------------------------------------------------" +
            `\nInspector's comment: ${item.comments || ""}` +
            "\n-------------------------------------------------" +
            "\nCause:" +
            "\n-------------------------------------------------" +
            "\nResolution:",
          references
        },
        taskDetail["@odata.etag"],
      );
      return createTaskRes.id;
    }
  }
  return undefined;
};

export const plannerProcess = async (
  checklistItems: ChecklistItemSubmission[],
  checklistRecord: ChecklistRecordDetail,
  msPlannerUploadCallbacks: MSPlannerUploadCallback[]
): Promise<ChecklistItemSubmission[]> => {
  if (checklistItems.every(x => !x.createDefect)) return checklistItems;

  const items: ChecklistItemSubmission[] = [...checklistItems];
  if (!checklistRecord) return items;

  const tokenInfo = await MSPlannerApi.getMSGraphToken();

  if (tokenInfo) {
    const token = tokenInfo.accessToken;
    const groupData = await MSPlannerApi.getAllGroups(token);

    if (groupData?.value[0].id) {
      const drive = await MSPlannerApi.getDrive(token, groupData.value[0].id);

      const channelsData = await MSPlannerApi.getAllChannels(token, groupData.value[0].id);
      // HACK: find by !@#$%^&*()_+ to return null when projectCode be undefined
      const currentChannel = channelsData?.value.find(c => c.displayName.includes(checklistRecord.projectCode || "!@#$%^&*()_+"))
        || channelsData?.value.find(c => c.displayName.includes(DEFAULT_CHANNEL_NAME));

      const plannersData = await MSPlannerApi.getAllPlanners(token, groupData.value[0].id);
      const currentPlan = plannersData?.value.find(p => p.title.includes(checklistRecord.projectCode || "!@#$%^&*()_+"))
        || plannersData?.value.find(p => p.title.includes(DEFAULT_PLAN_NAME));

      if (currentPlan?.id) {
        const bucket = await MSPlannerApi.getBucketByPlanIdPath(token, currentPlan.id);
        const currentBucket = bucket?.value.find(b => b.name === DEFAULT_BUCKET_NAME);

        if (currentBucket?.id) {
          for await (const item of checklistItems) {
            if (item.createDefect) {
              item.msPlannerTaskId = await createMSTask(
                item,
                token,
                currentBucket.id,
                currentPlan.id,
                drive?.id || "",
                currentChannel?.displayName || "",
                moment().format('HH-mm-ss MM-DD-YY'),
                checklistRecord,
                msPlannerUploadCallbacks
              );
            }
          }
        }
      }
    }
  }

  return items;
};
