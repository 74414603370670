import { Button, Col, Image, Layout, Row, Typography } from "antd";
import { Auth } from "aws-amplify";
import { AzureProvider } from "config/constants";
import { FC, useEffect } from "react";

const { Content } = Layout;

const Login: FC = () => {
  const onSignIn = async () => await Auth.federatedSignIn({ customProvider: AzureProvider });
  return (
    <Content className="h-100vh d-flex flex-column justify-center bg-login">
      <Row justify="center" className="mb-5">
        <Col span={6} className="d-flex align-items-center justify-content-center">
          <Image preview={false} src="/tophat.svg" alt="tophat-logo" width="200" />
          <Typography.Title className="ms-3 mb-1 text-success">TopHat</Typography.Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={6}>
          <Button id="login-btn" type="primary" size="large" className="w-100 rounded-3" onClick={onSignIn}>
            Sign In
          </Button>
        </Col>
      </Row>
    </Content>
  );
};

export default Login;
