export enum TYPE_COLUMN {
    LINK = "LINK",
    LABEL = "LABEL",
    STRING = "STRING",
    DATE = "DATE",
    ACTION = "ACTION",
};

export enum RADIO_TYPE {
    MR = 'Mr',
    MS = 'Ms',
    MRS = 'Mrs',
    OTHER = 'OTHER',
    NONE = 'NONE',
};

export interface IResponse<T> {
    message: string;
    data: IResData<T>;
}

export interface IResData<T> {
    resModel: T[];
    nextPageIndex: number | null;
    total: number;
}

export interface IClient {
    id: string;
    clientType: string;
    clientName: string;
    website: null | string;
    description: null | string;
    billingAddress: null | string;
    createdBy: null | string;
    createdDate: null | string;
    updatedBy: null | string;
    updatedDate: null | string;
}
export interface IContact {
    id: string;
    contactName: string;
    clientName: string;
    email: string;
    mobilePhone: null | string;
    officePhone: null | string;
    receiveEmails: boolean;
    description: null | string;
    createdBy: null | string;
    createdDate: null | string;
    updatedBy: null | string;
    updatedDate: null | string;
}

export interface IContactDetail {
    id: string;
    contactName: string;
    salutation: string;
    clientName: string;
    firstName: string;
    lastName: string;
    email: string;
    title?: null | string | undefined;
    mobilePhone: null | string | undefined;
    officePhone: null | string | undefined;
    receiveEmails: boolean;
    description: null | string | undefined;
    isActive: boolean;
}

export interface IClientDetail {
    id: string;
    logoImageUri: null | string | undefined;
    clientType: string;
    clientName: string;
    website: null | string | undefined;
    description: null | string | undefined;
    billingAddress: null | string | undefined;
}

export interface INote {
    id: string;
    content: string;
    createdBy: string | null;
    createdDate: string | null;
    updatedBy: string | null;
    updatedDate: string | null;
    permission: boolean;
}

export interface ISuggest {
    id?: string;
    name: string;
}

export type ColumnTitleType = {
    title: string;
    columnName: string;
    type?: TYPE_COLUMN;
    style?: React.CSSProperties;
    width?: number;
    pathname?: string;
    isOpenNewPage?: boolean;
    hasPermission?: boolean;
    isHidden?: boolean;
    onClickAction?: (id: string) => void;
};

export interface DataTypeClient {
    id: string;
    clientType: string;
    clientName: string;
    website: string | null;
    description: string | null;
    billingAddress: string | null;
    createdBy: string | null;
    createdDate: string | null;
    updatedBy: string | null;
    updatedDate: string | null;
};

export interface DataTypeContact {
    id: string;
    contactName: string;
    clientName: string;
    email: string;
    mobilePhone: null | string;
    officePhone: null | string;
    description: null | string;
    createdBy: null | string;
    createdDate: null | string;
    updatedBy: null | string;
    updatedDate: null | string;
};

export type TypeBodyAddClient = {
    id?: string;
    logoImageUri?: string;
    clientName: string;
    clientTypes: string[];
    website?: string;
    address?: string;
    description?: string;
};

export type TypeBodyAddClientType = {
    name: string;
};

export type TypeBodyAddContact = {
    id?: string;
    salutation: string;
    clientName: string;
    firstName: string;
    lastName: string;
    email: string;
    title?: string;
    mobilePhone?: string;
    officePhone?: string;
    receiveEmails: boolean;
    description?: string;
};

export type TypeBodyAddNote = {
    id: string;
    content: string;
};

export type TypeParams = {
    nextPageIndex: number;
    searchKeyword?: string;
    groupByClientTypes?: string;
    groupByClientNames?: string;
    groupByCreatedBys?: string;
    groupBySearchKeyword?: string;
    groupByContactSearchKeyword?: string;
};

export type ActivitiesType = {
    clientId: string
    owners: string[]
    contacts: string[]
    status: string[]
    dueDate: string[]
}
export interface ActivityType {
    id: string
    title: string
    description: string
    dueDate: string
    isCompleted: boolean
    isArchived: boolean
    completionDate: any
    completionId: any
    completionName: any
    contact: {
        id: string
        name: string
    }
    owner: {
        id: string
        name: string
    }
    createdDate: string
    createdById: string
    createdBy: string
    updatedDate: any
    updatedById: any
    updatedByName: any
}

export type SubmittedI = {
    contact: string;
    description: string;
    dueDate: string;
    owner: string;
    title: string;
    clientId?: string | undefined;
    activityId?: string | undefined;
};

