import { message } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import { ChecklistRecord, ChecklistRecordDetail } from "types/models";
import { RecordFilter } from "types/systems";

const apiName = "checklistRecordApi";
const topHatApiName = "tophatApi";

const recordPath = "/api/checklistRecord";
const checkDraftRecordPath = "/api/checklistRecord/checkDraftRecord";
const recordForExportPath = "/api/checklistRecord/recordForExport";
const detailRecordPath = "/api/checklistRecord/detail";
const houseTypePath = "/api/checklistRecord/houseType";
const moduleNoPath = "/api/checklistRecord/moduleNo";
const updateStatusLockingPath = "/api/checklistRecord/edit";
const signOffPath = "/api/checklistRecord/signOff";
const exportPath = "/export/checklistRecords";

const ChecklistRecordApi = {
  getHouseTypes: (filter: any = null): Promise<string[]> =>
    API.get(apiName, houseTypePath, {
      queryStringParameters: {
        projectId: (filter && filter.projectCode) ? filter.projectCode.join(",") : null,
        moduleNo: (filter && filter.moduleNo) ? filter.moduleNo.join(",") : null,
      }
    })
      .then((data) => data.resModel)
      .catch(() => {
        message.error("Network Error: Cannot get list House Type!");
        return [];
      }),
  getModuleNos: (filter: any = null): Promise<string[]> =>
    API.get(apiName, moduleNoPath, {
      queryStringParameters: {
        projectId: (filter && filter.projectCode) ? filter.projectCode.join(",") : null,
        houseType: (filter && filter.houseType) ? filter.houseType.join(",") : null,
      }
    })
      .then((data) => data.resModel)
      .catch(() => {
        message.error("Network Error: Cannot get list Module No!");
        return [];
      }),
  getRecords: (filter: RecordFilter, nextPageToken = ""): Promise<{ data: ChecklistRecord[]; nextToken: string }> =>
    API.get(apiName, recordPath, {
      queryStringParameters: {
        projectCode: filter.projectCode.join(","),
        moduleNo: filter.moduleNo.join(","),
        inspectionStatus: filter.inspectionStatus.join(","),
        houseType: filter.houseType.join(","),
        inspector: filter.inspector.join(","),
        from: filter.from,
        to: filter.to,
        ...(filter.sortBy ? { sortBy: filter.sortBy } : {}),
        ...(filter.sortDirection ? { sortDirection: filter.sortDirection } : {}),
        nextPageToken
      }
    })
      .then((data) => ({ data: data.resModel, nextToken: data.nextPageToken }))
      .catch(() => {
        message.error("Network Error: Cannot get list of inspection record!");
        return { data: [], nextToken: "" };
      }),
  getRecordDetail: (query: { moduleId?: string; checklistId?: string }): Promise<ChecklistRecordDetail | undefined> =>
    API.get(apiName, detailRecordPath, {
      queryStringParameters: { ...query }
    })
      .then((data) => data.resModel)
      .catch(() => {
        message.error("Network Error: Cannot get inspection record!");
        return undefined;
      }),
  putRecord: (record: ChecklistRecordDetail): Promise<{ id: string; checklistId: string } | undefined> =>
    API.put(apiName, recordPath, { body: record })
      .then((data) => data.resModel)
      .catch((e) => {
        const data = e?.response?.data;
        if (data?.statusCode === 400) {
          message.error(data?.message);
        } else {
          message.error("Network Error: Cannot save checklist result");
        }
        return undefined;
      }),
  checkDraftRecord: (moduleId: string): Promise<any> =>
    API.get(apiName, checkDraftRecordPath, {
      queryStringParameters: { moduleId }
    })
      .then((data) => data)
      .catch(() => undefined),
  deleteDraftRecord: (record: ChecklistRecordDetail | undefined): Promise<any> =>
    API.del(apiName, recordPath, { body: record })
      .then(() => message.success("Deleted draft record successfully"))
      .catch(() => message.error("Network Error: Cannot delete draft record")),
  updateStatusLocking: (moduleId: string): Promise<boolean> =>
    API.put(apiName, updateStatusLockingPath, { body: { moduleId } })
      .then(() => true)
      .catch(() => false),
  updateSignOff: (checklistId = ""): Promise<{ checklistId: string; signOff: boolean } | undefined> =>
    API.post(apiName, signOffPath, { body: { checklistId } })
      .then((data) => data.resModel)
      .catch(() => undefined),
  exportRecords: (filter: RecordFilter): Promise<void> =>
    API.get(topHatApiName, exportPath, {
      queryStringParameters: {
        projectCode: filter.projectCode.join(","),
        moduleNo: filter.moduleNo.join(","),
        inspectionStatus: filter.inspectionStatus.join(","),
        houseType: filter.houseType.join(","),
        inspector: filter.inspector.join(","),
        from: filter.from,
        to: filter.to,
        ...(filter.sortBy ? { sortBy: filter.sortBy } : {}),
        ...(filter.sortDirection ? { sortDirection: filter.sortDirection } : {})
      },
      response: true
    })
      .then((response) => {
        const raw = window.atob(response.data);
        const uInt8Array = new Uint8Array(raw.length);
        for (let i = 0; i < raw.length; ++i) {
          uInt8Array[i] = raw.charCodeAt(i);
        }
        const blob = new Blob([uInt8Array], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.download = `Inspection-Checklist-Records_${moment().format("YYYYMMDD")}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(() => {
        message.error("Network Error: Cannot export inspection records!");
      })
};

export default ChecklistRecordApi;
