
import "./index.scss";

function LabelInfo({ label, value, isLink }: { label: string; value: string, isLink?: boolean }): JSX.Element {
  return (
    <p className="general-info-label">
      {label}:{" "}
      {!isLink && (<span className="general-info-value">{value}</span>)}
      {isLink && (<a className="general-info-Link" target="_blank" href={value}>{value}</a>)}
    </p>
  );
};

export default LabelInfo;