import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { RoleMatrix, UserProfile } from "types/systems/auth-state";

const usePermission = () => {
  const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user);
  const checkPermission = (actionName?: string): boolean => {
    try {
      let hasPermission = false
      let currentRoles = []
      if (user?.attributes?.profile) {
        if (user?.attributes?.profile.includes(",")) {
          currentRoles = user.attributes.profile.substring(1, user.attributes.profile.length - 1).split(",")?.map(role => role.trim())
        } else {
          currentRoles = [user?.attributes?.profile?.trim()]
        }
        currentRoles.forEach((roleName: string) => {
          if (user.roleMatrix && Object.keys(user.roleMatrix).includes(roleName) && actionName) {
            if ((user.roleMatrix as RoleMatrix)[roleName]?.actions?.includes(actionName)) {
              hasPermission = true
            }
          }
        })
      }
      return hasPermission
    } catch (error) {
      console.error('Error checking permission:', error);
      return false
    }
  };

  return { checkPermission };
};

export { usePermission };