import { Spin } from "antd";
import { setUser } from "app/app.slice";
import { RootState } from "app/store";
import { Auth, Hub } from "aws-amplify";
import Login from "pages/Login";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from 'lodash'
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { UserProfile } from "types/systems/auth-state";
import AppLayout from "./AppLayout";
import { API } from "aws-amplify";
import { CRM_API, CRM_APP_ROLES } from "config/constants";
const App = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useSelector<RootState, UserProfile | undefined>((state) => state.auth.user);
  useEffect(() => {
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      setIsLoading(true);
      switch (event) {
        case "signIn":
          dispatch(setUser(data));
          break;
        case "signOut":
          dispatch(setUser(undefined));
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          break;
        case "tokenRefresh":
          console.log("token refresh succeeded");
          break;
        case "tokenRefresh_failure":
          console.log("token refresh failed");
          break;
      }
      setIsLoading(false);
    });

    (async () => {
      setIsLoading(true);
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        console.log("🚀 ~ file: App.tsx:43 ~ currentUser:", currentUser)
        const localRoleMatrix = localStorage.getItem("roleMatrix")
        let memoRoleMatrix = null
        if(localRoleMatrix) {
          memoRoleMatrix = JSON.parse(localRoleMatrix)
          dispatch(setUser({...currentUser, roleMatrix: memoRoleMatrix}));
          setIsLoading(false);
        } else {
          const roleMatrix = await API.get(CRM_API, CRM_APP_ROLES, {
            queryStringParameters: {}
          });
          dispatch(setUser({...currentUser, roleMatrix: roleMatrix.data}));
          localStorage.setItem("roleMatrix", JSON.stringify(roleMatrix.data))
          setIsLoading(false);
        }
      } catch (e) {
        console.log("🚀 ~ file: App.tsx:54 ~ e:", e)
        setIsLoading(false);
      }
    })();
  }, []);

  return (
    <Spin spinning={isLoading}>
      {user && !_.isEmpty(user.roleMatrix) ? (
        <>
          <div
            id="tooltip-212123"
            style={{
              position: "absolute",
              display: "none",
              zIndex: 1000,
              margin: 0,
              padding: 10,
              backgroundColor: "#ffffff",
              border: "1px solid #cccccc",
              whiteSpace: "nowrap"
            }}
          ></div>
          <AppLayout user={user as UserProfile} />
        </>
      ) : (
        <Login />
      )}
    </Spin>
  );
};

export default App;
