/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "checklistApi",
            "endpoint": "https://2xmt3n01m0.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "checklistRecordApi",
            "endpoint": "https://ixuhwatup8.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "checklistTemplateApi",
            "endpoint": "https://o187gfhupg.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "crmApi",
            "endpoint": "https://zxe8f6z2g2.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "entityApi",
            "endpoint": "https://x1uqr467ig.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "projectApi",
            "endpoint": "https://ckbv95gf4i.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "quicksightApi",
            "endpoint": "https://84vz23dsli.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "suggestApi",
            "endpoint": "https://k66tyi4sk2.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "todoApi",
            "endpoint": "https://tw1889bov6.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "tophatApi",
            "endpoint": "https://j9af05hmg1.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-west-2:09bb6acd-d80d-4ba9-876a-ae5c3a353bbf",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_MAgAQ4WJE",
    "aws_user_pools_web_client_id": "55b2cotrganiu46cbtcsbpeebt",
    "oauth": {
        "domain": "tophatprod-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://tophatmvp.uk/",
        "redirectSignOut": "https://tophatmvp.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tophatstaticfile134443-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2"
};


export default awsmobile;
