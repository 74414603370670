import { createAsyncThunk, createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import FailureTypeSuggestionApi from "api/failureTypeSuggestion";
import FailureType from "types/models/failure-type";

export const fetchFailureTypes = createAsyncThunk("failureType/fetch", async () => {
  return await FailureTypeSuggestionApi.getAll();
});

export const addFailureType = createAsyncThunk("failureType/add", async (failureType: string, thunkAPI) => {
  await FailureTypeSuggestionApi.addFailureType(failureType);
  await thunkAPI.dispatch(fetchFailureTypes());
});

export const updateFailureType = createAsyncThunk(
  "failureType/update",
  async ({ failureType, isAddSub }: { failureType: FailureType; isAddSub?: boolean }, thunkAPI) => {
    await FailureTypeSuggestionApi.updateFailureType(failureType, isAddSub);
    await thunkAPI.dispatch(fetchFailureTypes());
  }
);

export const removeFailureType = createAsyncThunk("failureType/remove", async (id: string, thunkAPI) => {
  await FailureTypeSuggestionApi.deleteFailureType(id);
  await thunkAPI.dispatch(fetchFailureTypes());
});

export interface FailureTypeState {
  failureTypes: FailureType[];
  loading: boolean;
  error: string;
}

const failureTypeSlice = createSlice<FailureTypeState, {}, "failureType">({
  name: "failureType",
  initialState: { failureTypes: [], loading: false, error: "" },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchFailureTypes.fulfilled, (state, action: PayloadAction<FailureType[]>) => {
        state.failureTypes = action.payload;
        state.loading = false;
      })
      .addMatcher(
        isAnyOf(
          fetchFailureTypes.pending,
          addFailureType.pending,
          updateFailureType.pending,
          removeFailureType.pending
        ),
        (state) => {
          state.loading = true;
        }
      )
      .addMatcher(
        isAnyOf(addFailureType.fulfilled, updateFailureType.fulfilled, removeFailureType.fulfilled),
        (state) => {
          state.loading = false;
        }
      );
  }
});

export default failureTypeSlice.reducer;
