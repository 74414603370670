import Storage from "@aws-amplify/storage";
import AppFooter from "AppFooter";
import AppHeader from "AppHeader";
import routes from "AppRoutes";
import { DownloadProvider } from "DownloadProvider";
import { Layout, Spin, message } from "antd";
import ChecklistRecordApi from "api/checklistRecord";
import Sidebar from "components/Sidebar";
import localforage from "localforage";
import { FlashMsg } from "pages/CRMAccountManagement/components";
import { FC, Suspense, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ChecklistRecordDetail } from "types/models";
import { UserProfile } from "types/systems/auth-state";
import { getFormValue } from "utils/form-builder";
import { plannerProcess } from "utils/msPlanner";
import { FormProvider, useForm } from "react-hook-form";
import { usePermission } from "hooks/usePermission";

const { Content } = Layout;
const AppLayout: FC<{ user?: UserProfile }> = ({ user }) => {
  const { checkPermission } = usePermission();
  const methods = useForm()
  const dataURLtoFile = (dataurl: any, filename: any) => {
    const arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  useEffect(() => {
    window.addEventListener("online", async () => {
      try {
        const offlineItems: any = JSON.parse((await localforage.getItem("offlineItems")) || "{}");
        console.log("offlineItems", offlineItems);
        for (const key in offlineItems) {
          const value: any = offlineItems[key] || {};
          const uploadCallbacks: any[] = JSON.parse(value.offlineUploadCallbacks || "[]");
          const offlineForm: any = JSON.parse(value.offlineForm || "null");
          const checklistRecord: any = JSON.parse(value.offlineChecklistRecord || "null");
          const msPlannerUploadCallbacks: any[] = JSON.parse(value.offlineMsPlannerUploadCallbacks || "[]");
          const draftChecklistId: any = JSON.parse(value.offlineDraftChecklistId || "null");
          const typeSubmit: any = JSON.parse(value.offlineTypeSubmit || "null");
          const template: any = JSON.parse(value.offlineTemplate || "null");

          if (offlineForm && checklistRecord && typeSubmit && template) {
            if (uploadCallbacks && uploadCallbacks.length > 0) {
              const funcs = uploadCallbacks.map(async (cb: any) => {
                if (cb.type == "remove") {
                  await Storage.remove(cb.fileName);
                  const result = offlineForm.rest[cb.idType] || "";
                  const newResult = result.split(",").filter((r: string) => r !== cb.fileName);
                  offlineForm.rest[cb.idType] = newResult.join(",");
                } else if (cb.type == "add") {
                  const file = dataURLtoFile(cb.file, cb.fileName);
                  const res = await Storage.put(`${cb.fileId}/${cb.fileName}`, file, {
                    contentType: "*"
                  });
                  const fileResult = offlineForm.rest[cb.idType] || "";
                  offlineForm.rest[cb.idType] = fileResult + (fileResult ? "," : "") + res.key;
                }
              });
              await Promise.all(funcs);
            }
            const {
              otherComment,
              finalInspectionResult,
              qualityPermissionToSite,
              weightedFailRate = 0,
              weightedPassRate = 0,
              note
            } = offlineForm.formValue;
            const rest = offlineForm.rest;
            const schema = offlineForm.schema;
            const content = getFormValue(
              rest,
              schema,
              typeSubmit,
              checklistRecord.inspectionStatus,
              checklistRecord.checklistItems,
              draftChecklistId
            );
            const checklistItems = await plannerProcess(content.checkItems, checklistRecord, msPlannerUploadCallbacks);
            const submission: ChecklistRecordDetail = {
              ...checklistRecord,
              checklistId: draftChecklistId || checklistRecord.checklistId,
              inspectionStatus: draftChecklistId ? "Draft" : checklistRecord.inspectionStatus,
              version:
                (checklistRecord.version || 0) +
                (draftChecklistId && checklistRecord.inspectionStatus !== "Draft" ? 1 : 0),
              issueCreated: content.issuesCreated,
              comments: otherComment,
              finalInspectionResult,
              qualityPermissionToSite,
              updatedInspectionStatus: typeSubmit,
              moduleId: checklistRecord.moduleNo,
              templateId: template.id,
              templateName: template.name,
              templateType: template.type,
              weightedFailRate,
              weightedPassRate,
              checklistItems,
              projectName: undefined,
              projectCode: undefined,
              moduleNo: undefined,
              houseType: undefined,
              inspector: undefined,
              note
            };
            const res = await ChecklistRecordApi.putRecord(submission);
            if (res) {
              delete offlineItems[key];
              message.success(
                typeSubmit === "Submitted"
                  ? `Sync Submitted Inspection ${key} Result successfully!`
                  : `Sync Draft Inspection ${key} Result successfully!`
              );
              console.log("before save offlineItems", offlineItems);
              await localforage.setItem("offlineItems", JSON.stringify(offlineItems));
            }
          }
        }
      } catch (error) {}
    });
    window.addEventListener("offline", () => {});
  }, []);
  return (
    <DownloadProvider>
      <Layout hasSider>
        <Sidebar
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: "/tophat.svg",
            imgTextSrc: "/tophatText.svg",
            imgAlt: "tophat-logo"
          }}
        />
        <Layout className="site-layout">
          <AppHeader user={user as UserProfile} />
          <FlashMsg />
          <FormProvider {...methods}>
            <Content>
              <div className="site-main site-drawer-render-in-current-wrapper">
                <Suspense fallback={<Spin size="small"></Spin>}>
                  <Switch>
                    {routes
                      .filter((childRoute) => checkPermission(childRoute?.role) || !childRoute?.role)
                      .map((route, key) =>
                        route.children ? (
                          route.children
                            .filter((childRoute) => checkPermission(childRoute?.role) || !childRoute?.role)
                            .map((childRoute, childKey) => (
                              <Route
                                exact
                                path={childRoute.path}
                                component={childRoute.component}
                                key={`${key}-${childKey}`}
                              />
                            ))
                        ) : (
                          <Route exact path={route.path} component={route.component} key={key} />
                        )
                      )}
                    <Redirect from="*" to="/dashboard/all-projects" />
                  </Switch>
                </Suspense>
              </div>
            </Content>
          </FormProvider>

          <AppFooter />
        </Layout>
      </Layout>
    </DownloadProvider>
  );
};

export default AppLayout;
