import { cloneDeep } from "lodash";
import React, { createContext, useContext, useState, useRef } from "react";

export interface DownloadProviderValue {
  files: any;
  setFiles: any;
  addDownload?: any;
  removeDownload?: any;
}

export const DownloadContext = createContext<DownloadProviderValue>({
  files: [],
  setFiles: () => {}
});

export const DownloadProvider: React.FC = ({ children }) => {
  const [files, setFiles] = useState<any>([]);
  const preFiles = useRef<any>([]);
  const addDownload = (f: any) => {
    const filesTmp = cloneDeep(files);
    filesTmp.push(f);
    setFiles(filesTmp);
    preFiles.current = filesTmp;
  };
  const removeDownload = (f: any) => {
    const filesTmp = cloneDeep(preFiles.current);
    const index = filesTmp.indexOf(f);
    if (index > -1) {
      filesTmp.splice(index, 1);
    }
    setFiles(filesTmp);
    preFiles.current = filesTmp;
  };
  return (
    <DownloadContext.Provider
      value={{
        files,
        setFiles,
        addDownload,
        removeDownload
      }}
    >
      {children}
    </DownloadContext.Provider>
  );
};

export const useDownload = (): DownloadProviderValue => {
  return useContext(DownloadContext);
};
