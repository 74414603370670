import { message } from "antd";
import { API } from "aws-amplify";
import FailureType from "types/models/failure-type";

const apiName = "checklistRecordApi";

const failureTypeSuggestionPath = "/api/checklistRecord/failureTypeSuggestion";

const FailureTypeSuggestionApi = {
  getAll: (): Promise<FailureType[]> =>
    API.get(apiName, failureTypeSuggestionPath, {})
      .then((data) =>
        data.resModel.map((x: any) => ({
          id: x.id,
          failureType: x.failure_type,
          failureSubType: x.failure_sub_type ? x.failure_sub_type.split("|") : []
        }))
      )
      .catch(() => {
        message.error("Network Error: Cannot get Failure Types!");
        return [];
      }),
  addFailureType: (failureType: string): Promise<void> =>
    API.post(apiName, failureTypeSuggestionPath, {
      body: { failureType, failureSubType: "" }
    })
      .then(() => message.success("Add Failure Type successfully!"))
      .catch(() => message.error("Network Error: Cannot add Failure Type!")),
  updateFailureType: (failureType: FailureType, isAddSub = true): Promise<void> =>
    API.put(apiName, failureTypeSuggestionPath, {
      body: { ...failureType, failureSubType: failureType.failureSubType.join("|") }
    })
      .then(() => message.success(`${isAddSub ? "Add" : "Remove"} Failure Sub-Type successfully!`))
      .catch(() => message.error(`Network Error: Cannot ${isAddSub ? "add" : " remove"} Failure Sub-Type!`)),
  deleteFailureType: (id: string): Promise<void> =>
    API.del(apiName, failureTypeSuggestionPath, { body: { id } })
      .then(() => message.success("Delete Failure Type Successfully!"))
      .catch(() => message.error("Network Error: Cannot delete Failure Type!"))
};

export default FailureTypeSuggestionApi;
