import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import React, { FC, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import useBrowserSize from "pages/BillOfMaterials/hooks/useBrowserSize";
import { GALAXY_TAB_A7_BREAKPOINT_W, PAGE_SIZE } from "config/constants";
import { Route } from "types/route";
import { usePermission } from "hooks/usePermission";
const { Sider } = Layout;
const { SubMenu } = Menu;
interface SidebarPropType {
  routes?: Route[];
  logo: {
    innerLink?: string;
    outerLink?: string;
    imgSrc: string;
    imgTextSrc: string;
    imgAlt: string;
  };
}
const Sidebar: FC<SidebarPropType> = ({ routes = [], logo }) => {
  const { checkPermission } = usePermission();
  const location = useLocation();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState<any>([]);
  const [openKeys, setOpenKeys] = useState<any>([]);

  const getSelectedKey = (pathname: any) => {
    if (pathname === "/") {
      return "/dashboard/all-projects";
    } else {
      if (pathname.split("/").length === 4) {
        return pathname
          .split("/")
          .filter((e: any, i: any) => i < pathname.split("/").length - 1)
          .join("/");
      } else {
        return pathname;
      }
    }
  };

  const [w, h] = useBrowserSize();
  const isTablet = w <= GALAXY_TAB_A7_BREAKPOINT_W;
  useEffect(() => {
    setSelectedKeys([getSelectedKey(location.pathname)]);
    setOpenKeys([location.pathname === "/" ? "dashboard" : location.pathname.split("/")[1]]);
  }, [location]);

  return (
    <Sider
      className="tophat-sider"
      width={240}
      collapsible={isTablet ? false : true}
      collapsed={isTablet ? true : collapsed}
      onCollapse={() => setCollapsed(!collapsed)}
      theme="light"
      trigger={!collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
      reverseArrow={true}
      style={{
        overflow: "hidden",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0
      }}
    >
      <Link to={"/dashboard/all-projects"}>
        <div className="logo">
          <img src={logo.imgSrc} alt="logo" />
          <img src={logo.imgTextSrc} alt="logoText" className="site-logo-text" />
        </div>
      </Link>
      <Menu
        theme="light"
        selectedKeys={selectedKeys}
        openKeys={openKeys}
        onOpenChange={(openKeys: any) => setOpenKeys(openKeys)}
        mode="inline"
      >
        {routes
          .filter((x) => x.menu)
          .map((route, key) => (
            <React.Fragment key={key}>
              {route.divider && <Menu.Divider />}
              {route.children && (checkPermission(route.role) || !route?.role) ? (
                <SubMenu key={route.children[0].path?.split("/")[1]} icon={<route.icon />} title={route.name}>
                  {route.children
                    .filter((childRoute) => checkPermission(childRoute?.role) || !childRoute?.role)
                    .map((childRoute) => (
                      <Menu.Item key={childRoute.path} onClick={() => history.push(`${childRoute.path}`)}>
                        {childRoute.name}
                      </Menu.Item>
                    ))}
                </SubMenu>
              ) : (
                (checkPermission(route?.role) || !route?.role) && (
                  <Menu.Item
                    className={`${
                      route.path?.split("/")[1] === window.location.href.split("/")[3] && "highlight-menu-item"
                    } `}
                    key={route.path}
                    icon={<route.icon />}
                    onClick={() => history.push(`${route.path}`)}
                  >
                    {route.name}
                  </Menu.Item>
                )
              )}
            </React.Fragment>
          ))}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
