import { BellOutlined, DownOutlined, UserOutlined } from "@ant-design/icons";
import Auth from "@aws-amplify/auth";
import { Avatar, Badge, Breadcrumb, Button, Divider, Dropdown } from "antd";
import { RECORD_COLUMNS } from "constants/checklist-record";
import _ from "lodash";
import { useLocalStorage } from "pages/BillOfMaterials/hooks/useLocalStorage";
import { FC, MouseEventHandler } from "react";
import { useLocation } from "react-router-dom";
import { UserProfile } from "types/systems/auth-state";

const AppHeader: FC<{ user?: UserProfile }> = ({ user }) => {
  const location = useLocation();
  const [_1, setShowColumnsMaterialCataLog] = useLocalStorage("antdTableColumnMaterialCataLog", []);
  const [_2, setShowColumnsBomList] = useLocalStorage("antdTableColumnBomList", []);

  const logout: MouseEventHandler<HTMLElement> = async (e) => {
    e.preventDefault();
    setShowColumnsMaterialCataLog([]);
    setShowColumnsBomList([]);
    localStorage.setItem(RECORD_COLUMNS, "");
    localStorage.removeItem("roleMatrix");
    await Auth.signOut();
  };

  const getBreadcrumbName = (l: any) => {
    if (l.includes("?")) return l.split("?")[0];

    if (l == "bom-list") {
      return "BoM List";
    }
    if (l == "crm-config") {
      return "CRM Config";
    }
    return _.capitalize(l.replaceAll("-", " "));
  };

  const formatRouteBreadcrumb = () => {
    const splitRoute = location.pathname.split("/").filter((x) => x !== "");
    const arrayURL = [];
    for (let index = 0; index < splitRoute.length; index++) {
      let route = `/${splitRoute[0]}`;
      if (index > 0) {
        route = route + `/${splitRoute[index]}` + `${location.search.trim().length > 0 ? `/${location.search}` : ""}`;
      }

      arrayURL.push({
        name:
          index > 0 &&
          splitRoute[0] === "pipeline-management" &&
          splitRoute[1].trim() !== "add-new-opportunity" &&
          splitRoute[1].trim().length > 0
            ? "-"
            : getBreadcrumbName(splitRoute[index]),
        href: route
      });
    }
    return arrayURL;
  };

  return (
    <div className="site-header-container">
      <div className="site-header-left">
        <Breadcrumb separator=">">
          {formatRouteBreadcrumb().map((l) => (
            <Breadcrumb.Item key={l.name} href={l.href}>
              {l.name}
            </Breadcrumb.Item>
          ))}
        </Breadcrumb>
      </div>
      <div className="site-header-right">
        <Badge dot>
          <BellOutlined />
        </Badge>
        <Divider type="vertical" />
        <Avatar size="small" icon={<UserOutlined />} />
        <Dropdown
          overlay={
            <div className="site-header-user-menu">
              <div className="user-menu-avatar">
                <Avatar icon={<UserOutlined />} />
              </div>
              <div className="user-menu-name">{user?.attributes?.email}</div>
              <div className="user-menu-email">{user?.username}</div>
              <Divider className="user-menu-devider" />
              <Button onClick={logout}>Sign out</Button>
            </div>
          }
          trigger={["click"]}
          placement="bottomRight"
        >
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            {user?.attributes?.email} <DownOutlined />
          </a>
        </Dropdown>
      </div>
    </div>
    // <div className="site-header">
    //   <Breadcrumb separator=">">
    //     {formatRouteBreadcrumb()
    //       .map((l) => (
    //         <Breadcrumb.Item key={l.name} href={l.href}>
    //           {l.name}
    //         </Breadcrumb.Item>
    //       )
    //       )}
    //   </Breadcrumb>
    //   <div className="site-header-action">
    //     <Badge dot>
    //       <BellOutlined />
    //     </Badge>
    //     <Divider type="vertical" />
    //     <Avatar size="small" icon={<UserOutlined />} />
    //     <Dropdown
    //       overlay={
    //         <div className="site-header-user-menu">
    //           <div className="user-menu-avatar">
    //             <Avatar icon={<UserOutlined />} />
    //           </div>
    //           <div className="user-menu-name">{user?.attributes?.email}</div>
    //           <div className="user-menu-email">{user?.username}</div>
    //           <Divider className="user-menu-devider" />
    //           <Button onClick={logout}>Sign out</Button>
    //         </div>
    //       }
    //       trigger={["click"]}
    //       placement="bottomRight"
    //     >
    //       <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
    //         {user?.attributes?.email} <DownOutlined />
    //       </a>
    //     </Dropdown>
    //   </div>
    // </div>
  );
};

export default AppHeader;
