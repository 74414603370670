export const AUTO_SAVE_DRAFT_INSPECTION_TIME = 120000; // 2 minutes
export const RECORD_COLUMNS = "RECORD_COLUMNS";
export const MAX_FILE_SIZE = 4194304;
export const MAX_NUMBER_FILES_PER_CHECKLIST = 7;
export const CheckResult = {
  Pass: "Pass",
  FailL1: "Fail-L1",
  FailL2: "Fail-L2",
  FailL3: "Fail-L3"
};
