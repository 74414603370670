import { configureStore } from "@reduxjs/toolkit";
import auth from "./app.slice";
import failureType from "./failureType.slice";

export const store = configureStore({
  reducer: {
    auth,
    failureType
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: process.env.NODE_ENV === "development"
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
