import _ from "lodash";
import { useState } from "react";

// Hook
export function useLocalStorage(key: string, initialValue: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      if (item) {
        return JSON.parse(item);
      } else if (initialValue) {
        return initialValue;
      } else if (!item && !initialValue) {
        return null;
      }
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: any) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  let final;
  if (!_.isNil(initialValue) && !_.isEmpty(initialValue)) {
    const columns = _.intersection(
      storedValue,
      initialValue
    );
    final = _.isNil(columns) || _.isEmpty(columns) ? initialValue : columns;
  }

  return [final, setValue];
}
