import { message } from "antd";
import { API, Storage } from 'aws-amplify';
import { v4 as uuidV4 } from 'uuid';

import { CRM_API } from "config/constants";
import { ActivitiesType, ActivityType, IClient, IClientDetail, IContact, IContactDetail, INote, IResponse, ISuggest, SubmittedI, TypeBodyAddClient, TypeBodyAddClientType, TypeBodyAddContact, TypeBodyAddNote, TypeParams } from "types/models/account-management";
import { Helpers, constants } from "pages/CRMAccountManagement/utils";
import { UploadFile } from "antd/lib/upload/interface";

const endPoint = {
  GET_CLIENT_NAME_IS_EXISTS: "/crm/clients/isValidClientName",
  GET_CLIENT_TYPE_IS_EXISTS: "/crm/clients/isValidClientType",
  GET_CONTACT_IS_EXISTS: "/crm/contacts/isValidContactName",

  GET_LIST_CLIENT: "/crm/clients/list",
  GET_LIST_CLIENT_SEARCH: "/crm/clients/filter",
  GET_SUGGEST_CLIENT_NAME: "/crm/clients/suggest/clientName",
  GET_SUGGEST_CLIENT_TYPE: "/crm/clients/suggest/clientType",
  GET_LIST_CLIENT_DETAIL: "/crm/clients/",

  GET_LIST_CLIENT_NOTES: "/crm/clients/notes/",

  GET_LIST_CONTACT: "/crm/contacts/list",
  GET_LIST_CONTACT_SEARCH: "/crm/contacts/filter",
  GET_LIST_CONTACT_NAME: "/crm/contacts/suggest/contactName",
  GET_SUGGEST_CONTACT_CREATED_BY: "/crm/contacts/suggest/createdBy",
  GET_LIST_CONTACT_DETAIL: "/crm/contacts/",
  GET_LIST_OPP_DETAIL: "/crm/opportunities/detail",

  GET_LIST_ACTIVITIES: "/crm/clients/activity/list",
  GET_LIST_OWNER_FILTER: "/crm/clients/activity/filter/owners/",
  GET_LIST_CONTACT_FILTER: "/crm/clients/activity/filter/contacts/",
  CHANGE_STATUS: "/crm/clients/activity/updateStatus",
  ADD_NEW_ACTIVITY: "/crm/clients/activity/add",
  EDIT_ACTIVITY: "/crm/clients/activity/update",

  POST_ADD_CLIENT: "/crm/clients/add",
  POST_ADD_CLIENT_NOTE: "/crm/clients/notes/add",
  POST_ADD_CONTACTS: "/crm/contacts/add",
  POST_ADD_CLIENT_TYPE: "/crm/clients/addClientType",

  PUT_UPDATE_CLIENT: "/crm/clients/update",
  PUT_UPDATE_CLIENT_NOTE: "/crm/clients/notes/update",
  PUT_UPDATE_CONTACT: "/crm/contacts/update",
  PUT_UPDATE_STATUS_CONTACT: "/crm/contacts/update/status",

  DEL_CONTACT: "/crm/contacts/delete/", //:id
  DEL_CLIENT_NOTES: "/crm/clients/notes/",
};

const defaultData = {
  message: '',
  data: {
    resModel: [],
    nextPageIndex: 0,
    total: 0,
  }
};

const handleErrorMessage = (error: any) => {
  if (error?.response?.data?.message) {
    message.error(error?.response?.data?.message);
  }
};

const AccountManagementAPI = {
  isValidClientName: async ({ name, id }:
    { name: string; id?: string; }) => {
    let result = false;

    try {
      const queryStringParameters: { name: string; id?: string; } = { name: encodeURI(name) };

      if (id) {
        queryStringParameters.id = id;
      }

      if (queryStringParameters.name == '') {
        result = true;
      }
      else {
        const res: { message: string; data: { isValid: boolean } } =
          await API.get(CRM_API, endPoint.GET_CLIENT_NAME_IS_EXISTS, { queryStringParameters });

        result = res.data.isValid;
      }

    } catch (error) {
      handleErrorMessage(error);
    }

    return result;
  },
  isValidClientType: async (name: string) => {
    let result = false;

    try {
      const res: { message: string; data: { isValid: boolean } } =
        await API.get(CRM_API, endPoint.GET_CLIENT_TYPE_IS_EXISTS, { queryStringParameters: { name: encodeURI(name) } });

      result = res.data.isValid;
    } catch (error) {
      handleErrorMessage(error);
    }

    return result;
  },
  isValidContact: async ({ email, id }:
    { email: string; id?: string; }) => {
    let result = false;

    try {
      const queryStringParameters: { email: string; id?: string; } = { email };

      if (id) {
        queryStringParameters.id = id;
      }

      const res: { message: string; data: { isValid: boolean } } =
        await API.get(CRM_API, endPoint.GET_CONTACT_IS_EXISTS, { queryStringParameters });

      result = res.data.isValid;
    } catch (error) {
      handleErrorMessage(error);
    }

    return result;
  },
  getListClient: async (nextPageIndex: number, params: { orderBy?: string, sortOrder?: string, }) => {
    let res: IResponse<IClient> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CLIENT,
        { queryStringParameters: { nextPageIndex, ...params } });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListClientSearch: async (
    nextPageIndex: number,
    searchKeyword: string,
    clientNames: string[] | null,
    clientTypes: string[] | null,
    params: { orderBy?: string, sortOrder?: string, }
  ) => {
    let res: IResponse<IClient> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CLIENT_SEARCH,
        {
          queryStringParameters: {
            nextPageIndex,
            searchKeyword,
            clientNames: Helpers.convertArrayToParams(clientNames),
            clientTypes: Helpers.convertArrayToParams(clientTypes),
            ...params
          }
        });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListSuggestClientName: async (queryStringParameters: TypeParams) => {
    let res: IResponse<ISuggest> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_SUGGEST_CLIENT_NAME, { queryStringParameters });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListSuggestClientType: async (queryStringParameters: TypeParams) => {
    let res: IResponse<ISuggest> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_SUGGEST_CLIENT_TYPE, { queryStringParameters });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListContact: async (
    nextPageIndex: number,
    params: { orderBy?: string, sortOrder?: string, }
  ) => {
    let res: IResponse<IContact> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CONTACT,
        { queryStringParameters: { nextPageIndex, ...params } });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListContactSearch: async (
    nextPageIndex: number,
    searchKeyword: string,
    clientNames: string[] | null,
    createdBys: string[] | null,
    params: { orderBy?: string, sortOrder?: string, }
  ) => {
    let res: IResponse<IContact> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CONTACT_SEARCH,
        {
          queryStringParameters: {
            nextPageIndex,
            searchKeyword,
            clientNames: Helpers.convertArrayToParams(clientNames),
            createdBys: Helpers.convertArrayToParams(createdBys),
            ...params
          }
        });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListSuggestContactName: async (queryStringParameters: TypeParams) => {
    let res: IResponse<ISuggest> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CONTACT_NAME, { queryStringParameters });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListSuggestCreateBy: async (queryStringParameters: TypeParams) => {
    let res: IResponse<ISuggest> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_SUGGEST_CONTACT_CREATED_BY, { queryStringParameters });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getListNote: async (id: string, nextPageIndex: number) => {
    let res: IResponse<INote> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CLIENT_NOTES + id,
        { queryStringParameters: { nextPageIndex } });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getContactDetail: async (id: string) => {
    let res: IResponse<IContactDetail> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CONTACT_DETAIL + id, {});
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getClientDetail: async (id: string) => {
    let res: IResponse<IClientDetail> = defaultData;

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_CLIENT_DETAIL + id, {});
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  uploadFile: async (file: UploadFile) => {
    try {
      const id = uuidV4();
      const fileExtension = Helpers.getFileExtension(file.name).toLowerCase();
      const imageName = `${constants.FOLDER_LOGO_IMAGE_CLIENT}-${id}.${fileExtension}`;
      const s3Key = await Storage.put(imageName, file.originFileObj, { contentType: file.type });

      return s3Key.key;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  deleteFile: async (path: string) => {
    try {
      await Storage.remove(path);
    } catch (error) {
      Promise.reject(error);
    }
  },
  getFile: async (path: string) => {
    let uri = '';

    try {
      uri = await Storage.get(path);
    } catch (error) {
      return Promise.reject(error);
    }

    return uri;
  },
  getTabOpportunity: async (id: string, nextPageIndex = 0) => {
    return API.get(CRM_API, endPoint.GET_LIST_OPP_DETAIL, {
      queryStringParameters: {
        tab: "client",
        id: id,
        nextPageIndex: nextPageIndex,
      }
    });
  },

  getTabActivities: async (queryStringParameters: ActivitiesType) => {
    let res: {
      message: string;
      data: { items: ActivityType[], page: number, pageSize: number, total: number, totalPage: number };
    } = {
      message: "",
      data: {
        items: [],
        page: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0
      }
    };

    try {
      res = await API.get(CRM_API, endPoint.GET_LIST_ACTIVITIES, { queryStringParameters: queryStringParameters });
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getOwnerListFilter: async (queryStringParameters: { 
    clientId: string | undefined, nextPageIndex: number, action: "addnew" | "filter" | "edit"
    , currentOwnerId?: string | undefined
    , currentContactId?: string | undefined, q?: string | undefined
  }) => {
    let res: {
      message: string;
      data: { items: { ownerId: string, ownerName: string }[], page: number, pageSize: number, total: number, totalPage: number };
    } = {
      message: "",
      data: {
        items: [],
        page: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0
      }
    };
    try {
      if (queryStringParameters.clientId) {
        res = await API.get(CRM_API, endPoint.GET_LIST_OWNER_FILTER, { queryStringParameters: queryStringParameters });
      }
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },
  getContactListFilter: async (queryStringParameters: { 
    clientId: string | undefined, nextPageIndex: number, action: "addnew" | "filter" | "edit"
    , currentOwnerId?: string | undefined
    , currentContactId?: string | undefined, q?: string | undefined
  }) => {
    let res: {
      message: string;
      data: { items: { contactId: string, contactName: string }[], page: number, pageSize: number, total: number, totalPage: number };
    } = {
      message: "",
      data: {
        items: [],
        page: 0,
        pageSize: 0,
        total: 0,
        totalPage: 0
      }
    };
    try {
      if (queryStringParameters.clientId) {
        res = await API.get(CRM_API, endPoint.GET_LIST_CONTACT_FILTER, { queryStringParameters: queryStringParameters });
      }
    } catch (error) {
      handleErrorMessage(error);
    }

    return res.data;
  },

  changeStatusActivity: async (body: { id: string, status: boolean, actionCode: "COMPLETED" | "ARCHIVED" }) => {
    return await API.put(CRM_API, endPoint.CHANGE_STATUS, { body });
  },
  addActivityForClient: async (body: SubmittedI | {}) => {
    return await API.post(CRM_API, endPoint.ADD_NEW_ACTIVITY, { body });
  },
  editActivityForClient: async (body: SubmittedI | {}) => {
    return await API.put(CRM_API, endPoint.EDIT_ACTIVITY, { body });
  },

  addClient: async (body: TypeBodyAddClient) => {
    return await API.post(CRM_API, endPoint.POST_ADD_CLIENT, { body });
  },
  addClientType: async (body: TypeBodyAddClientType) => {
    return await API.post(CRM_API, endPoint.POST_ADD_CLIENT_TYPE, { body });
  },
  addContact: async (body: TypeBodyAddContact) => {
    return await API.post(CRM_API, endPoint.POST_ADD_CONTACTS, { body });
  },
  addNote: async (body: TypeBodyAddNote) => {
    await API.post(CRM_API, endPoint.POST_ADD_CLIENT_NOTE, { body });
  },
  updateContact: async (body: TypeBodyAddContact) => {
    await API.put(CRM_API, endPoint.PUT_UPDATE_CONTACT, { body });
  },
  updateClient: async (body: TypeBodyAddClient) => {
    await API.put(CRM_API, endPoint.PUT_UPDATE_CLIENT, { body });
  },
  updateNote: async (body: TypeBodyAddNote) => {
    await API.put(CRM_API, endPoint.PUT_UPDATE_CLIENT_NOTE, { body });
  },
  delNote: async (id: string) => {
    await API.del(CRM_API, endPoint.DEL_CLIENT_NOTES + id, {});
  },
  delContact: async (id: string) => {
    await API.del(CRM_API, endPoint.DEL_CONTACT + id, {});
  },
  updateStatusContact: async (id: string, status: boolean) => {
    try {
      await API.put(CRM_API, endPoint.PUT_UPDATE_STATUS_CONTACT, {
        body: { id, status }
      });
    } catch (error) {
      handleErrorMessage(error);
    }
  },
};

export default AccountManagementAPI;
