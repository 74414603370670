import {
  AppstoreOutlined,
  CarryOutOutlined,
  ControlOutlined,
  SettingOutlined,
  TeamOutlined,
  ToolOutlined,
  ProfileOutlined,
  AntDesignOutlined
} from "@ant-design/icons";
import { lazy } from "react";
import { Route } from "types/route";

import { ReactComponent as AccountManagementSvg } from "assets/icons/accountManagement.svg";
import { ReactComponent as PipelineManagementSvg } from "assets/icons/pipelineManagement.svg";
import { ReactComponent as ProjectAllocationSvg } from "assets/icons/projectAllocation.svg";
import { ACCOUNT_MANAGEMENT_ROLE, CRM_CONFIG_ROLE, DASHBOARD_ROLE, PIPELINE_MANAGEMENT_ROLE, PROJECT_ALLOCATION_ROLE } from "config/role";

const routes: Route[] = [
  {
    path: "/dashboard/all-projects/:id",
    menu: false,
    name: "Project Detail",
    component: lazy(() => import("pages/Dashboard/AllProjects/detail")),
    role: DASHBOARD_ROLE.ROOT_DASHBOARD
  },
  {
    name: "Dashboard",
    icon: AppstoreOutlined,
    menu: true,
    children: [
      {
        path: "/dashboard/executive-dashboard",
        name: "Executive Dashboard",
        component: lazy(() => import("pages/Dashboard/ExecutiveDashboard"))
      },
      // {
      //   path: "/dashboard/production-dashboard",
      //   name: "Production Dashboard",
      //   component: lazy(() => import("pages/Dashboard/ProductionDashboard"))
      // },
      {
        path: "/dashboard/kpi-dashboard",
        name: "KPI Dashboard",
        component: lazy(() => import("pages/Dashboard/KPIDashboard"))
      },
      {
        path: "/dashboard/production-dashboard",
        name: "Production Dashboard",
        component: lazy(() => import("pages/Dashboard/ProductionDashboard"))
      },
      {
        path: "/dashboard/sales-dashboard",
        name: "Sales Dashboard",
        component: lazy(() => import("pages/Dashboard/SalesDashboard"))
      },
      {
        path: "/dashboard/all-projects",
        name: "All Projects",
        component: lazy(() => import("pages/Dashboard/AllProjects"))
      }
    ]
  },
  {
    path: "/pipeline-management/add-new-opportunity",
    menu: false,
    name: "Add New Opportunity",
    component: lazy(() => import("pages/CRMPipelineManagement/AddNewOppoturnity")),
    role: PIPELINE_MANAGEMENT_ROLE.CREATE_OPPORTUNITY
  },
  {
    path: "/pipeline-management/:id",
    menu: false,
    name: "Opportunity Detail",
    component: lazy(() => import("pages/CRMPipelineManagement/Detail")),
    role: PIPELINE_MANAGEMENT_ROLE.VIEW_OPPORTUNITY_DETAILS
  },
  {
    name: "Pipeline Management",
    icon: PipelineManagementSvg,
    menu: true,
    path: "/pipeline-management",
    component: lazy(() => import("pages/CRMPipelineManagement")),
    role: PIPELINE_MANAGEMENT_ROLE.ROOT_PIPELINE_MANAGEMENT
  },
  {
    path: "/account-management/client/add-client",
    name: "Add Client",
    menu: false,
    component: lazy(() => import("pages/CRMAccountManagement/AddClient")),
    role: ACCOUNT_MANAGEMENT_ROLE.CREATE_CLIENT_INFO
  },
  {
    path: "/account-management/client/:code",
    name: "Client Detail",
    menu: false,
    component: lazy(() => import("pages/CRMAccountManagement/ClientDetail")),
    role: ACCOUNT_MANAGEMENT_ROLE.VIEW_CLIENT_DETAILS
  },
  {
    path: "/account-management/contact/add-contact",
    name: "Add Contact",
    menu: false,
    component: lazy(() => import("pages/CRMAccountManagement/AddContact")),
    role: ACCOUNT_MANAGEMENT_ROLE.CREATE_CONTACT_INFO
  },
  {
    path: "/account-management/contact/:code",
    name: "Contact Detail",
    menu: false,
    component: lazy(() => import("pages/CRMAccountManagement/ContactDetail")),
    role: ACCOUNT_MANAGEMENT_ROLE.VIEW_CONTACT_DETAILS
  },
  {
    name: "Account Management",
    icon: AccountManagementSvg,
    menu: true,
    role: ACCOUNT_MANAGEMENT_ROLE.ROOT_ACCOUNT_MANAGEMENT,
    children: [
      {
        path: "/account-management/client",
        name: "Client",
        component: lazy(() => import("pages/CRMAccountManagement/Client")),
        role: ACCOUNT_MANAGEMENT_ROLE.VIEW_CLIENT_LIST
      },
      {
        path: "/account-management/contact",
        name: "Contact",
        component: lazy(() => import("pages/CRMAccountManagement/Contact")),
        role: ACCOUNT_MANAGEMENT_ROLE.VIEW_CONTACT_LIST
      }
    ]
  },
  {
    path: "/project-allocation",
    name: "Project Allocation",
    icon: ProjectAllocationSvg,
    menu: true,
    component: lazy(() => import("pages/CRMProjectAllocation")),
    role: PROJECT_ALLOCATION_ROLE.ROOT_PROJECT_ALLOCATION
  },
  {
    path: "/bill-of-materials/material-catalog/:id",
    name: "Material Catalog Detail",
    menu: false,
    component: lazy(() => import("pages/BillOfMaterials/MaterialCatalog/components/MaterialCatalogDetail"))
  },
  {
    path: "/bill-of-materials/bom-list/:moduleTypeAndBomNumber",
    name: "BoM List Detail",
    menu: false,
    component: lazy(() => import("pages/BillOfMaterials/BoMList/components/BomListRouter"))
  },
  {
    name: "CRM Config",
    icon: ToolOutlined,
    menu: true,
    role: CRM_CONFIG_ROLE.ROOT_CRM_CONFIG,
    children: [
      {
        path: "/crm-config/products",
        name: "Products",
        component: lazy(() => import("pages/CRMConfig/Products")),
        role: CRM_CONFIG_ROLE.VIEW_PRODUCT_LIST
      },
      {
        path: "/crm-config/forecast-assumptions",
        name: "Sales Forecast Assumption",
        component: lazy(() => import("pages/CRMConfig/ForecastAssumptions")),
        role: CRM_CONFIG_ROLE.VIEW_TIMELINE_ASSUMPTION
      }
    ]
  },
  {
    name: "Bill Of Materials",
    icon: TeamOutlined,
    menu: true,
    children: [
      {
        path: "/bill-of-materials/material-catalog",
        name: "Material Catalog",
        component: lazy(() => import("pages/BillOfMaterials/MaterialCatalog"))
      },
      {
        path: "/bill-of-materials/bom-list",
        name: "BoM List",
        component: lazy(() => import("pages/BillOfMaterials/BoMList"))
      }
    ]
  },
  // {
  //   path: "/design-library",
  //   name: "Design Library",
  //   icon: ProfileOutlined,
  //   menu: true,
  //   component: lazy(() => import("pages/DesignLibrary"))
  // },
  {
    path: "/quality-management/inspection-library/create",
    name: "Create Checklist",
    menu: false,
    component: lazy(() => import("pages/QualityManagement/ChecklistCreate"))
  },
  {
    path: "/quality-management/quality-center/inpsection-record",
    component: lazy(() => import("pages/QualityManagement/ChecklistRecord")),
    menu: false
  },
  {
    path: "/quality-management/inspection-library/:tab",
    name: "Inspection Library",
    menu: false,
    component: lazy(() => import("pages/QualityManagement/ChecklistLibrary"))
  },
  {
    path: "/quality-management/quality-center/:tab",
    name: "Quality Center",
    menu: false,
    component: lazy(() => import("pages/QualityManagement/QualityCenter"))
  },
  {
    name: "Quality Management",
    icon: CarryOutOutlined,
    menu: true,
    children: [
      {
        path: "/quality-management/inspection-library",
        name: "Inspection Library",
        component: lazy(() => import("pages/QualityManagement/ChecklistLibrary"))
      },
      {
        path: "/quality-management/quality-center",
        name: "Quality Center",
        component: lazy(() => import("pages/QualityManagement/QualityCenter"))
      }
    ]
  },
  {
    path: "/design/revit-family/:code",
    name: "Rvt Detail",
    menu: false,
    component: lazy(() => import("pages/DesignRevit/RevitDetail"))
  },
  {
    name: "Design",
    icon: AntDesignOutlined,
    menu: true,
    children: [
      {
        path: "/design/revit-family",
        name: "Revit Family",
        component: lazy(() => import("pages/DesignRevit/RevitFamily"))
      }
    ]
  },
  {
    path: "/module-storage",
    name: "Module Storage",
    icon: ControlOutlined,
    menu: true,
    component: lazy(() => import("pages/ModuleStorage"))
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingOutlined,
    menu: true,
    divider: true,
    component: lazy(() => import("pages/Settings"))
  },
  //CRM Page
  {
    path: "/crm/todos",
    name: "CRM Todo",
    icon: ControlOutlined,
    menu: false,
    component: lazy(() => import("pages/CRM/Todo"))
  },
  {
    path: "/crm-config/forecast-assumptions",
    name: "CRM Config Timeline Assumptions",
    icon: ControlOutlined,
    menu: false,
    component: lazy(() => import("pages/CRMConfig/ForecastAssumptions"))
  },
  {
    path: "/demo/dragdrop",
    name: "Demo Drag Drop",
    icon: ControlOutlined,
    menu: false,
    component: lazy(() => import("pages/Demo/DragDrop"))
  }
];

export default routes;
