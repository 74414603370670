import { PlusOutlined } from "@ant-design/icons";
import { Form, Input, Upload, message } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { RcFile, UploadChangeParam, UploadProps } from "antd/lib/upload";
import { UploadFile } from "antd/lib/upload/interface";
import { useCallback, useEffect, useState } from "react";

import AccountManagementAPI from "api/accountManagement";
import { cloneDeep } from "lodash";
import { IResData, ISuggest, TypeBodyAddClient } from "types/models/account-management";
import { CustomSelect, ModalAddClientType } from "../../components";
import localization from "../../localization";
import { Helpers, constants } from "../../utils";
import "./index.scss";

let idTimeoutCheckClientName: NodeJS.Timeout | undefined = undefined;
const fileAccept = "image/png, image/jpeg";

type FormClientProps = {
  isNotShowLabelImage?: boolean;
  form: FormInstance<TypeBodyAddClient>;
  initialValues?: Partial<TypeBodyAddClient>;
  id?: string;
  headerComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  image: UploadFile[];
  setImage: (img: UploadFile[]) => void;
};

function FormClient(props: FormClientProps) {
  const { isNotShowLabelImage, form, initialValues, id, headerComponent, footerComponent, image, setImage } = props;

  const [isVisibleModalAddClientType, setIsVisibleModalAddClientType] = useState(false);
  const [isExistClientName, setIsExistClientName] = useState<boolean>(false);
  const [clientTypesSelected, setClientTypesSelected] = useState<string[]>(initialValues?.clientTypes ?? []);
  
  const [numberOfCreatedClientTypeSuccessfully, setNumberOfCreatedClientTypeSuccessfully] = useState<number>(0);

  const _handleError = (error: any) => {
    console.log(error);
  };

  const handleBeforeUpload: any = async (file: RcFile, FileList: RcFile[]) => {
    if (!file.type || !fileAccept.includes(file.type)) {
      message.error("Only files with the following extensions are allow: JPG, JPEG, PNG!");
      return;
    }

    if (file.size > constants.MAX_FILE_SIZE) {
      return await Helpers.resizeFile(file);
    }

    return file;
  };

  const _handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile<any>>) => {
    if (!info.file.type || !fileAccept.includes(info.file.type)) {
      return;
    }

    setImage(info.fileList);
  };

  const _onChangeTxtClient = (e: any) => {
    if (!e?.target?.value || Helpers.isNullOrEmpty(e.target.value.trim())) {
      setIsExistClientName(false);
      return;
    }

    form
      .validateFields(["clientName"])
      .then(() => {
        if (idTimeoutCheckClientName) {
          clearTimeout(idTimeoutCheckClientName);
        }

        idTimeoutCheckClientName = setTimeout(async () => {
          try {
            const isValid = await AccountManagementAPI.isValidClientName({
              name: e.target.value.trim(),
              id
            });

            if (form.getFieldsValue().clientName != "") {
              setIsExistClientName(!isValid);
            }
          } catch (error) {
          } finally {
            idTimeoutCheckClientName = undefined;
          }
        }, 1000);
      })
      .catch((error) => {
      });
  };

  const _onCreateClientTypeSuccessfully = (val: {id: string, name: string}) => {
    setNumberOfCreatedClientTypeSuccessfully(numberOfCreatedClientTypeSuccessfully + 1);
    const cloneDeepClientTypes = cloneDeep(clientTypesSelected);
    cloneDeepClientTypes.push(val.name);
    setClientTypesSelected(cloneDeepClientTypes);
    form.setFieldsValue({
      clientTypes: cloneDeepClientTypes
    });
    setIsVisibleModalAddClientType(false);
    _onShowAlert();
  };

  const _showModalModalAddClientType = () => {
    setIsVisibleModalAddClientType(true);
  };

  const _onHideModalModalAddClientType = () => {
    setIsVisibleModalAddClientType(false);
  };

  const _onShowAlert = () => {
    message.success(localization.msgCreateClientTypeSuccessfully);
  };

  const _handleRemove = () => {
    setImage([]);
  };

  const _setClientTypesSelected = (value: string[]) => {
    setClientTypesSelected(value);

    form.setFieldsValue({
      clientTypes: value
    });
  };

  const _getListClientType = useCallback(async (nextPageIndex: number, keyword: string | undefined) => {
    const res: IResData<ISuggest> = {
      total: 0,
      nextPageIndex: null,
      resModel: []
    };

    if (nextPageIndex == null) {
      return res;
    }

    return await AccountManagementAPI.getListSuggestClientType({
      nextPageIndex,
      searchKeyword: !keyword || Helpers.isNullOrEmpty(keyword) ? undefined : keyword.trim()
    });
  }, [numberOfCreatedClientTypeSuccessfully]);

  useEffect(() => {
    if (initialValues && initialValues?.clientTypes) {
      setClientTypesSelected(initialValues?.clientTypes);
    }
  }, [initialValues]);

  return (
    <>
      <Form
        layout="vertical"
        validateMessages={{ required: localization.msgRequired }}
        form={form}
        initialValues={initialValues}
        className="viewAddClient"
      >
        {headerComponent}
        <Form.Item
          label={isNotShowLabelImage ? "" : localization.logoImage}
          valuePropName="fileList"
          className="txtLogoImage"
        >
          <Upload
            action={undefined}
            multiple={false}
            listType="picture-card"
            accept={fileAccept}
            maxCount={1}
            beforeUpload={handleBeforeUpload}
            onChange={_handleChange}
            fileList={image}
            onRemove={_handleRemove}
            openFileDialogOnClick={true}
          >
            {(!image || image.length == 0) && (
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }} className="placeHolderUpload">
                  {localization.upload}
                </div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item
          name="clientName"
          validateStatus={isExistClientName ? "error" : undefined}
          help={isExistClientName ? localization.msgClientNameIsExist : undefined}
          label={localization.clientName}
          rules={[{ required: true }, { pattern: /.*\S.*/, message: localization.msgRequired }]}
        >
          <Input
            placeholder={localization.pleaseEnter}
            maxLength={255}
            className="txtInput"
            onChange={_onChangeTxtClient}
          />
        </Form.Item>
        <Form.Item
          name="clientTypes"
          className="w-100"
          label={localization.clientType}
          rules={[{ required: true }]}
        >
          <CustomSelect
            fncName="getClientType"
            mode="multiple"
            placeholder={localization.pleaseSelect}
            conditions={clientTypesSelected}
            setArrConditions={_setClientTypesSelected}
            handleError={_handleError}
            getListSuggest={_getListClientType}
          >
            <span
              className="add-new-button"
              style={{ color: "#00747a", padding: "4px", cursor: "pointer" }}
              onClick={_showModalModalAddClientType}
            >
              {"+ " + localization.addNew}
            </span>
          </CustomSelect>
        </Form.Item>
        <Form.Item name="website" label={localization.website} rules={[{ type: "url" }]}>
          <Input
            placeholder={localization.pleaseEnter}
            maxLength={255}
            className="txtInput"
            style={{ color: "#0d6efd" }}
          />
        </Form.Item>
        <Form.Item name="address" label={localization.address}>
          <Input placeholder={localization.pleaseEnter} maxLength={1000} className="txtInput" />
        </Form.Item>
        <Form.Item name="description" label={localization.description}>
          <Input.TextArea
            autoSize={{ minRows: 2, maxRows: 6 }}
            rows={2}
            placeholder={localization.pleaseEnter}
            maxLength={1000}
            className="txtInput"
          />
        </Form.Item>
        {footerComponent}
      </Form>

      <ModalAddClientType
        isVisible={isVisibleModalAddClientType}
        onClose={_onHideModalModalAddClientType}
        onConfirm={_onCreateClientTypeSuccessfully}
      />
    </>
  );
}

export default FormClient;
