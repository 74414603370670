import { API } from "aws-amplify";
import axios from "axios";
import { INSPECTION_TEAM_NAME } from "config/constants";
import { PlannerResponse, Drive, Channel, Plan, Bucket, Group, TaskDetail, CreateTaskResponse, MSUploadFileResponse } from "types/models";
import { MSAuthState } from "types/systems/auth-state";

const topHatApiName = "tophatApi";
const getGraphTokenPath = "/getMSAccessToken"

const getAllGroupsPath = `https://graph.microsoft.com/v1.0/groups?$filter=startswith(displayName,'${INSPECTION_TEAM_NAME}')`;
const getAllPlanersPath = (groupId: string) => `https://graph.microsoft.com/v1.0/groups/${groupId}/planner/plans`;
const getBucketByPlanIdPath = (planId: string) => `https://graph.microsoft.com/v1.0/planner/plans/${planId}/buckets`;
const createTaskPath = "https://graph.microsoft.com/v1.0/planner/tasks";
const uploadFilePath = (driveId: string, channelName: string, filename: string) =>
  `https://graph.microsoft.com/v1.0/drives/${driveId}/items/root:/${channelName}/${filename}:/content`;
const taskDetailPath = (id: string) => `https://graph.microsoft.com/v1.0/planner/tasks/${id}/details`;
const getDrivePath = (groupId: string) => `https://graph.microsoft.com/v1.0/groups/${groupId}/drive`;
const getAllChannelsPath = (groupId: string) => `https://graph.microsoft.com/v1.0/teams/${groupId}/channels`;

const MSPlannerApi = {
  getMSGraphToken: (): Promise<MSAuthState> =>
    API.get(topHatApiName, getGraphTokenPath, {})
      .then(data => data?.resModel)
      .catch(() => ({} as MSAuthState)),
  getDrive: (token: string, groupId: string): Promise<Drive | undefined> =>
    axios.get(getDrivePath(groupId), { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  getAllChannels: (token: string, groupId: string): Promise<PlannerResponse<Channel> | undefined> =>
    axios.get(getAllChannelsPath(groupId), { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  uploadFile: (token: string, driveId: string, channelName: string, filename: string, content: any): Promise<MSUploadFileResponse | undefined> =>
    axios.put(uploadFilePath(driveId, channelName, filename), content, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  getAllGroups: (token: string): Promise<PlannerResponse<Group> | undefined> =>
    axios.get(getAllGroupsPath, { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  getAllPlanners: (token: string, groupId: string): Promise<PlannerResponse<Plan> | undefined> =>
    axios.get(getAllPlanersPath(groupId), { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  getBucketByPlanIdPath: (token: string, planId: string): Promise<PlannerResponse<Bucket> | undefined> =>
    axios.get(getBucketByPlanIdPath(planId), { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  createTask: (token: string, data: { planId: string; bucketId: string; title: string; }): Promise<CreateTaskResponse | undefined> =>
    axios.post(createTaskPath,
      { ...data },
      { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  getTaskDetail: (token: string, taskId: string): Promise<TaskDetail | undefined> =>
    axios.get(taskDetailPath(taskId), { headers: { Authorization: `Bearer ${token}` } })
      .then(res => res.data)
      .catch(() => undefined),
  updateTaskDetail: (token: string, taskDetail: { id: string; description: string; references: any }, etag: string): Promise<{ id: string; description: string; } | undefined> =>
    axios.patch(taskDetailPath(taskDetail.id), {
      ...taskDetail
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
        'If-Match': etag
      }
    })
      .then(res => res.data)
      .catch(() => undefined),
};

export default MSPlannerApi;
