import { memo } from "react";

import localization from "pages/CRMAccountManagement/localization";
import { IResData, ISuggest } from "types/models/account-management";
import { CustomSelect } from "..";
import "./index.scss";


type TypeComboBoxProps = {
  title: string;
  conditions: string[];
  setConditions: (condition: string[]) => void;
  handleError: (error: any) => void;
  getListSuggest: (nextPageIndex: number, text: string | undefined) => Promise<IResData<ISuggest>>;
};

const ComboBox = (props: TypeComboBoxProps) => {
  const { title, setConditions, conditions, handleError, getListSuggest } = props;

  return (
    <div className="container comboBox">
      <label>{title}</label>
      <CustomSelect
        fncName={title.replace(' ', '')}
        placeholder={localization.all}
        mode="multiple"
        conditions={conditions}
        setArrConditions={setConditions}
        handleError={handleError}
        getListSuggest={getListSuggest}
      />
    </div>
  );
};

export default memo(ComboBox);
