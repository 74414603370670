import { FC } from "react";
import { useDownload } from "./DownloadProvider";
import CloudDownloadOutlined from "@ant-design/icons/CloudDownloadOutlined";
import LoadingOutlined from "@ant-design/icons/LoadingOutlined";
const AppFooter: FC = () => {
  const { files } = useDownload();
  if (files.length === 0) {
    return null;
  }
  return (
    <div className="download-loading">
      <div className="download-header">Preparing download</div>
      <div className="download-body">
        {files.map((f: any) => (
          <div key={f} className="download-file-list">
            <div className="download-file-icon-and-name">
              <CloudDownloadOutlined /> {f}
            </div>
            <div className="download-file-loading">
              <LoadingOutlined />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AppFooter;
