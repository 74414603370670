export const PIPELINE_MANAGEMENT_ROLE = {
    ROOT_PIPELINE_MANAGEMENT: "PIPELINE_MANAGEMENT",
    CREATE_OPPORTUNITY: "CREATE_OPPORTUNITY",
    VIEW_OPPORTUNITY_LIST: "VIEW_OPPORTUNITY_LIST",
    VIEW_OPPORTUNITY_LIST_CONTACTNAME: "VIEW_OPPORTUNITY_LIST_CONTACTNAME",
    VIEW_OPPORTUNITY_DETAILS: "VIEW_OPPORTUNITY_DETAILS",
    VIEW_OPPORTUNITY_DETAILS_GENERALINFO: "VIEW_OPPORTUNITY_DETAILS_GENERALINFO",
    VIEW_OPPORTUNITY_DETAILS_GENERALINFO_CONTACTNAME: "VIEW_OPPORTUNITY_DETAILS_GENERALINFO_CONTACTNAME",
    VIEW_OPPORTUNITY_DETAILS_PROJECTPHASE: "VIEW_OPPORTUNITY_DETAILS_PROJECTPHASE",
    VIEW_OPPORTUNITY_DETAILS_PLANNING: "VIEW_OPPORTUNITY_DETAILS_PLANNING",
    VIEW_OPPORTUNITY_DETAILS_PROJECTPROGRAM: "VIEW_OPPORTUNITY_DETAILS_PROJECTPROGRAM",
    VIEW_OPPORTUNITY_DETAILS_NOTE: "VIEW_OPPORTUNITY_DETAILS_NOTE",
    VIEW_OPPORTUNITY_DETAILS_ACTIVITY: "VIEW_OPPORTUNITY_DETAILS_ACTIVITY",
    VIEW_OPPORTUNITY_DETAILS_MAINPIC: "VIEW_OPPORTUNITY_DETAILS_MAINPIC",
    VIEW_OPPORTUNITY_DETAILS_STAKEHOLDERS: "VIEW_OPPORTUNITY_DETAILS_STAKEHOLDERS",
    VIEW_OPPORTUNITY_DETAILS_APPROVALREQUEST: "VIEW_OPPORTUNITY_DETAILS_APPROVALREQUEST",
    VIEW_OPPORTUNITY_DETAILS_KEYTERMS: "VIEW_OPPORTUNITY_DETAILS_KEYTERMS",
    VIEW_OPPORTUNITY_DETAILS_PAYMENTSCHEME: "VIEW_OPPORTUNITY_DETAILS_PAYMENTSCHEME",
    VIEW_OPPORTUNITY_DETAILS_LOSTOPPORTUNITY: "VIEW_OPPORTUNITY_DETAILS_LOSTOPPORTUNITY",
    EDIT_OPPORTUNITY_DETAILS: "EDIT_OPPORTUNITY_DETAILS",
    EDIT_OPPORTUNITY_DETAILS_GENERALINFO: "EDIT_OPPORTUNITY_DETAILS_GENERALINFO",
    EDIT_OPPORTUNITY_DETAILS_PROJECTPHASE: "EDIT_OPPORTUNITY_DETAILS_PROJECTPHASE",
    EDIT_OPPORTUNITY_DETAILS_PLANNING: "EDIT_OPPORTUNITY_DETAILS_PLANNING",
    EDIT_OPPORTUNITY_DETAILS_PROJECTPROGRAM: "EDIT_OPPORTUNITY_DETAILS_PROJECTPROGRAM",
    EDIT_OPPORTUNITY_DETAILS_NOTE: "EDIT_OPPORTUNITY_DETAILS_NOTE",
    EDIT_OPPORTUNITY_DETAILS_MAINPIC: "EDIT_OPPORTUNITY_DETAILS_MAINPIC",
    EDIT_OPPORTUNITY_DETAILS_STAKEHOLDERS: "EDIT_OPPORTUNITY_DETAILS_STAKEHOLDERS",
    EDIT_OPPORTUNITY_DETAILS_KEYTERMS: "EDIT_OPPORTUNITY_DETAILS_KEYTERMS",
    EDIT_OPPORTUNITY_DETAILS_PAYMENTSCHEME: "EDIT_OPPORTUNITY_DETAILS_PAYMENTSCHEME",
    VIEW_PIPELINE_BOARD: "VIEW_PIPELINE_BOARD",
    DELETE_OPPORTUNITY: "DELETE_OPPORTUNITY",
    MARK_OPPORTUNITY_AS_LOST: "MARK_OPPORTUNITY_AS_LOST",
    VIEW_LOST_OPPORTUNITY_LIST: "VIEW_LOST_OPPORTUNITY_LIST",
    CREATE_APPROVAL_REQUEST: "CREATE_APPROVAL_REQUEST",
    APPROVE_OR_REJECT_F1: "APPROVE_OR_REJECT_F1",
    APPROVE_OR_REJECT_F2: "APPROVE_OR_REJECT_F2",
    APPROVE_OR_REJECT_F3: "APPROVE_OR_REJECT_F3",
    APPROVE_OR_REJECT_F4: "APPROVE_OR_REJECT_F4",
    APPROVE_OR_REJECT_F5: "APPROVE_OR_REJECT_F5",
    APPROVE_OR_REJECT_F6: "APPROVE_OR_REJECT_F6",
    APPROVE_OR_REJECT_F7: "APPROVE_OR_REJECT_F7",
    APPROVE_OR_REJECT_F8: "APPROVE_OR_REJECT_F8"
  };
  export const DASHBOARD_ROLE = {
      ROOT_DASHBOARD: 'DASHBOARD',
      VIEW_SALES_REPORT: 'VIEW_SALES_REPORT'
  }
  export const CRM_CONFIG_ROLE = {
      ROOT_CRM_CONFIG: 'CRM_CONFIG',
      VIEW_PRODUCT_LIST : 'VIEW_PRODUCT_LIST',
      CREATE_PRODUCT: 'CREATE_PRODUCT',
      EDIT_PRODUCT_LIST: 'EDIT_PRODUCT_LIST',
      DELETE_PRODUCT: 'DELETE_PRODUCT',
      VIEW_CONVERSION_RATE_ASSUMPTION: 'VIEW_CONVERSION_RATE_ASSUMPTION',
      ADD_CONVERSION_RATE_ASSUMPTION: 'ADD_CONVERSION_RATE_ASSUMPTION',
      EDIT_CONVERSION_RATE_ASSUMPTION: 'EDIT_CONVERSION_RATE_ASSUMPTION',
      DELETE_CONVERSION_RATE_ASSUMPTION : 'DELETE_CONVERSION_RATE_ASSUMPTION',
      VIEW_TIMELINE_ASSUMPTION: 'VIEW_TIMELINE_ASSUMPTION',
      EDIT_TIMELINE_ASSUMPTION: 'EDIT_TIMELINE_ASSUMPTION',
      VIEW_PHASINGS: 'VIEW_PHASINGS',
      ADD_TIMELINE_ASSUMPTION: 'ADD_TIMELINE_ASSUMPTION',
  }
  export const ACCOUNT_MANAGEMENT_ROLE = {
      ROOT_ACCOUNT_MANAGEMENT: 'ACCOUNT_MANAGEMENT',
      CREATE_CLIENT_INFO: 'CREATE_CLIENT_INFO',
      VIEW_CLIENT_DETAILS: 'VIEW_CLIENT_DETAILS',
      EDIT_CLIENT_DETAILS: 'EDIT_CLIENT_DETAILS',
      VIEW_CLIENT_LIST: 'VIEW_CLIENT_LIST',
      VIEW_CONTACT_INFO_UNDER_CLIENT: 'VIEW_CONTACT_INFO_UNDER_CLIENT',
      VIEW_OPPORTUNITY_UNDER_CLIENT: 'VIEW_OPPORTUNITY_UNDER_CLIENT',
      CREATE_CLIENT_NOTE: 'CREATE_CLIENT_NOTE',
      VIEW_CLIENT_NOTE: 'VIEW_CLIENT_NOTE',
      EDIT_CLIENT_NOTE: 'EDIT_CLIENT_NOTE',
      CREATE_CONTACT_INFO: 'CREATE_CONTACT_INFO',
      VIEW_CONTACT_LIST: 'VIEW_CONTACT_LIST',
      VIEW_CONTACT_DETAILS: 'VIEW_CONTACT_DETAILS',
      EDIT_CONTACT_DETAILS: 'EDIT_CONTACT_DETAILS',
      DEACTIVATE_CONTACT: 'DEACTIVATE_CONTACT',
      REACTIVATE_CONTACT: 'REACTIVATE_CONTACT',
      DELETE_CONTACT: 'DELETE_CONTACT',

      VIEW_CLIENT_ACTITIVIES: 'VIEW_CLIENT_ACTIVITIES',
      EDIT_CLIENT_ACTIVITIES: 'EDIT_CLIENT_ACTIVITIES',
      EDIT_ACROSS_CLIENT_ACTIVITIES: 'EDIT_ACROSS_CLIENT_ACTIVITIES',
  }
  export const PROJECT_ALLOCATION_ROLE = {
      ROOT_PROJECT_ALLOCATION: 'PROJECT_ALLOCATION',
      ALLOCATE_PRODUCTION_MODULES: 'ALLOCATE_PRODUCTION_MODULES',
      EDIT_PRODUCTION_ALLOCATION: 'EDIT_PRODUCTION_ALLOCATION',
      VIEW_PRODUCTION_ALLOCATION: 'VIEW_PRODUCTION_ALLOCATION',
      VIEW_FACTORY_CAPACITY: 'VIEW_FACTORY_CAPACITY',
      EDIT_FACTORY_CAPACITY: 'EDIT_FACTORY_CAPACITY',
      ALLOCATE_DEPLOYMENT_MODULES: 'ALLOCATE_DEPLOYMENT_MODULES',
      EDIT_DEPLOYMENT_ALLOCATION: 'EDIT_DEPLOYMENT_ALLOCATION',
      VIEW_DEPLOYEMENT_ALLOCATION: 'VIEW_DEPLOYEMENT_ALLOCATION'
      }