const localization = {
    loadMore: 'Load more',
    search: 'Search',
    addClient: 'Add Client',
    addContact: 'Add Contact',
    clientName: 'Client Name',
    website: 'Website',
    clientType: 'Client Type',
    description: 'Description',
    job_title: 'Job Title',
    address: 'Address',
    createdBy: 'Created By',
    createdDate: 'Created Date',
    updatedBy: 'Updated By',
    updatedDate: 'Updated Date',
    action: 'Actions',
    delete: 'Delete',
    noResultsFound: 'No results found',
    clearAll: 'Clear all',
    all: 'All',
    cancel: 'Cancel',
    submit: 'Submit',
    save: 'Save',
    generalInformation: 'General information',
    pleaseEnter: 'Please enter',
    pleaseSelect: 'Please select',
    logoImage: 'Logo Image',
    upload: 'Upload',
    contactName: 'Contact Name',
    email: 'Email',
    mobilePhone: 'Mobile Phone',
    officePhone: 'Office Phone',
    markReceiveEmail: 'Does this contact want to receive emails from TopHat?',
    receiveEmail: "Receive emails from TopHat",
    salutation: 'Salutation',
    firstName: 'First Name',
    lastName: 'Last Name',
    addNew: 'Add New',
    addClientType: 'Add Client Type',
    note: 'Note',
    placeholderNote: "What's this note about?",
    edited: 'Edited',
    update: 'Update',
    contact: 'Contact',
    opportunity: 'Opportunity',
    active: 'Active',
    sort: 'Sort',
    reset: 'Reset',
    activities: 'Activities',
    viewArchivedActivities: 'View Archived Activities',
    viewUnarchivedActivities: 'Back to all activities',
    addActivity: 'Add Activity',

    msgConfirmDeactivate: 'Are you sure you want to deactivate this contact?',
    msgConfirmReactive: 'Are you sure you want to reactivate this contact?',

    msgRequired: 'This field is required.',
    msgWarningLimit1000: 'Text exceeds 1000 character limit',

    msgClientNameIsExist: 'This client name already exists!',
    msgClientTypeIsExist: 'This client type already exists!',
    msgEmailExists: 'This email already exists!',

    msgConfirmCancel: 'Are you sure you want to cancel?',
    msgConfirmCancelUpdate: 'Are you sure you want to discard all updates?',
    msgConfirmDelNote: 'Are you sure you want to delete this Note?',
    
    msgCreateClientSuccessfully: 'New Client is created successfully!',
    msgCreateClientTypeSuccessfully: 'New Client Type is created successfully!',
    msgCreateContactSuccessfully: 'New Contact is created successfully!',

    msgUpdateSuccessfully: 'Updated successfully',
    msgDelSuccessfully: 'Deleted successfully',
    
    msgCreateNoteSuccessfully: 'New Note is created successfully',

    msgCreateClientNameError: 'System error, please submit the Client again',
    msgCreateClientTypeError: 'System error, please submit the Client Type again',
    msgCreateContactError: 'System error, please submit the Contact again',

    msgUpdateContactError: 'System error, please update the Contact again',
    msgUpdateClientError: 'System error, please update the Client again',

    msgConfirmDelContact: 'Are you sure you want to delete this Contact?',

    msgCreateNoteError: 'System error, please submit the Note again',
    msgUpdateNoteError: 'System error, please update the Note again',
    msgDelNoteError: 'System error, please delete the Note again',
    msgDelContactError: 'System error, please delete the Contact again',
    
};

export default localization;